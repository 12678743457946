import {
  Alert,
  AlertColor,
  AlertTitle,
  LinearProgress,
  Typography,
} from '@mui/material'
import * as React from 'react'

export interface RenderMessageInterface {
  subject: string
  message?: any //intended to be string, but may be object if API response is parse with unexpected response
  severity?: AlertColor
  loading?: boolean
}

function RenderMessage({
  message,
  subjectFontSize = 20,
}: {
  message: RenderMessageInterface
  subjectFontSize?: number
}) {
  const severity = message?.severity || 'info'
  const loading = message?.loading || false
  let description = ''
  if (message?.message) {
    description =
      typeof message.message === 'string' ?
        message.message
      : JSON.stringify(message.message)
  }
  return (
    <Alert
      aria-label={'Rendered Message'}
      severity={severity}
      sx={{
        minWidth: 200,
        maxWidth: 'md',
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <AlertTitle>
        <Typography
          aria-label={'Subject'}
          fontWeight={'bold'}
          fontSize={subjectFontSize}
        >
          {message?.subject}
        </Typography>
      </AlertTitle>
      <Typography aria-label={'Message'}>{description}</Typography>
      {loading && (
        <LinearProgress
          color={severity}
          sx={{ mt: 2 }}
        />
      )}
    </Alert>
  )
}

export default RenderMessage
