import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { HeaderCell } from '../../../../components/Table'
import CellFormat from '../CellFormat'
import React from 'react'
import TableAlert from '../TableAlert'
import TableLoading from '../TableLoading'
import { usePatientContext } from '../../../PatientLoad'

function CriteriaTable({ maxHeight }: { maxHeight: number }) {
  const { criteriaData, criteriaLoading, patientData } = usePatientContext()

  return (
    <Paper
      elevation={5}
      sx={{ width: 1 }}
    >
      <TableContainer sx={{ width: 1, maxHeight: maxHeight }}>
        <Table
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <HeaderCell>Criteria</HeaderCell>
              <HeaderCell>{''}</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {criteriaData && (
              <>
                <TableRow>
                  <CellFormat value={'Cancer Type'} />
                  <CellFormat value={criteriaData?.type} />
                </TableRow>
                <TableRow>
                  <CellFormat value={'Cancer Morphology'} />
                  <CellFormat value={criteriaData?.morphology} />
                </TableRow>
                <TableRow>
                  <CellFormat value={'Cancer Topography'} />
                  <CellFormat value={criteriaData?.topography} />
                </TableRow>
                <TableRow>
                  <CellFormat value={'Genes'} />
                  <CellFormat value={criteriaData?.Genes} />
                </TableRow>
                <TableRow>
                  <CellFormat value={'Ranked List ID'} />
                  <CellFormat value={criteriaData?.id} />
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        {patientData?.criteria_id && criteriaLoading && <TableLoading />}
        {patientData?.criteria_id === null && (
          <TableAlert message={'CriteriaID is NULL - No Data'} />
        )}
      </TableContainer>
    </Paper>
  )
}

export default CriteriaTable
