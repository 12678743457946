import client from './API_Config'

async function getNotes(accessToken: Promise<string>, criteria_id: number) {
  return await client(await accessToken)
    .get<any>(`/myapi/criteria/${criteria_id}/note/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function addNote(
  accessToken: Promise<string>,
  criteria_id: number,
  body: any,
) {
  return await client(await accessToken)
    .post<any>(`/myapi/criteria/${criteria_id}/note/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const NoteService = {
  getNotes,
  addNote,
}

export default NoteService
