import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Code, Poll, UploadFile } from '@mui/icons-material'
import JsonUpload from './JsonUpload'
import FileUpload from './FileUpload'
import PollData from './PollData'
import { MTBRefInput } from '../../components/MTBrefInput'
import { format } from 'date-fns'

enum Operation {
  JSON = 1,
  Excel,
  Poll,
}

function Upload() {
  const [uploadType, setUploadType] = useState(Operation.JSON)

  const [mtbRef, setMtbRef] = useState<string>(format(new Date(), 'yyyy-MM-dd'))
  const [refValid, setRefValid] = useState<boolean>(true)

  return (
    <Box sx={{ maxWidth: 'md', width: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 1,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="h1">Upload / Poll</Typography>
        </Box>
        {MTBRefInput(mtbRef, setMtbRef, refValid, setRefValid)}
        <ButtonGroup
          color="primary"
          sx={{ m: 1 }}
        >
          <Button
            disabled={uploadType === Operation.JSON}
            onClick={() => setUploadType(Operation.JSON)}
            fullWidth
            startIcon={<Code />}
          >
            JSON
          </Button>
          <Button
            disabled={uploadType === Operation.Excel}
            onClick={() => setUploadType(Operation.Excel)}
            fullWidth
            startIcon={<UploadFile />}
          >
            Excel File
          </Button>
          <Button
            disabled={uploadType === Operation.Poll}
            onClick={() => setUploadType(Operation.Poll)}
            fullWidth
            startIcon={<Poll />}
          >
            Poll
          </Button>
        </ButtonGroup>
        <Box
          sx={{
            display: uploadType === Operation.JSON ? 'block' : 'none',
            width: 1,
          }}
        >
          {JsonUpload(mtbRef)}
        </Box>
        <Box
          sx={{
            display: uploadType === Operation.Excel ? 'block' : 'none',
            width: 1,
          }}
        >
          {FileUpload(mtbRef)}
        </Box>
        <Box
          sx={{
            display: uploadType === Operation.Poll ? 'block' : 'none',
            width: 1,
          }}
        >
          {PollData(mtbRef)}
        </Box>
      </Box>
    </Box>
  )
}

export default Upload
