import { Box, Button, FormHelperText, LinearProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Upload } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import ExtApi from '../../APIs/ExtApi'
import { useMessagePopupContext } from '../../garvan-react/Components/ModalFeedback/MessagePopup'

function JsonUpload(mtbRef: string) {
  const {setMessage, setError} = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()

  const [jsonInput, setJsonInput] = useState<string | undefined>(undefined)
  const [invalidInput, setInvalidJson] = useState('')

  function IsJsonString() {
    try {
      JSON.parse(jsonInput!)
    } catch (e) {
      return false
    }
    return true
  }

  function validInput() {
    if (jsonInput) {
      if (!IsJsonString()) {
        console.log('valid', IsJsonString())
        return 'Input is not valid JSON'
      }
    }
    return ''
  }

  const { mutate: uploadJson, isLoading: uploadLoading } = useMutation<
    any,
    AxiosResponse
  >(() => ExtApi.uploadJson(getAccessTokenSilently(), jsonInput, mtbRef), {
    onSuccess: (data) =>
      setMessage({ subject: 'Upload complete', message: data, severity: 'success' }),
    onError: (err) => setError(err),
  })

  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {uploadLoading && (
        <Box sx={{ width: 1, mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
      <Button
        startIcon={<Upload />}
        onClick={async () => await uploadJson()}
        sx={{ m: 2 }}
        variant="contained"
        disabled={!jsonInput || Boolean(invalidInput) || Boolean(uploadLoading)}
      >
        Upload
      </Button>
      <TextField
        fullWidth
        multiline
        minRows={5}
        value={jsonInput}
        margin="dense"
        onChange={(e) => {
          setInvalidJson('')
          setJsonInput(e.target.value)
        }}
        onBlur={() => setInvalidJson(jsonInput ? validInput() : '')}
        placeholder={'{}'}
        error={Boolean(invalidInput)}
        disabled={Boolean(uploadLoading)}
      />
      {invalidInput && <FormHelperText error>{invalidInput}</FormHelperText>}
    </Box>
  )
}

export default JsonUpload
