import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { HeaderCell } from '../../../../components/Table'
import CellFormat from '../CellFormat'
import React from 'react'
import TableLoading from '../TableLoading'
import TableAlert from '../TableAlert'
import { usePatientContext } from '../../../PatientLoad'

function VariantsTable({ maxHeight }: { maxHeight: number }) {
  const { criteriaData, criteriaLoading, patientData } = usePatientContext()

  return (
    <Paper
      elevation={5}
      sx={{ width: 1 }}
    >
      <TableContainer sx={{ width: 1, maxHeight: maxHeight }}>
        <Table
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <HeaderCell>Variants</HeaderCell>
              <HeaderCell></HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {criteriaData &&
              Object.entries(criteriaData.variants).map(
                ([key, value], index) => (
                  <TableRow key={index}>
                    <CellFormat value={key} />
                    <TableCell>
                      <Table size="small">
                        <TableBody>
                          {value &&
                            Object.entries(value).map(
                              ([subkey, subvalue], subindex) => (
                                <TableRow key={subindex}>
                                  <CellFormat value={subkey.toString()} />
                                  <CellFormat
                                    value={JSON.stringify(subvalue)}
                                  />
                                </TableRow>
                              ),
                            )}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
        {patientData?.criteria_id && criteriaLoading && <TableLoading />}
        {patientData?.criteria_id === null && (
          <TableAlert message={'CriteriaID is NULL - No Data'} />
        )}
      </TableContainer>
    </Paper>
  )
}

export default VariantsTable
