import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar, Box, ButtonBase, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'

import { AdminPanelSettings, CloudUpload, Description, Info, Search, Settings } from '@mui/icons-material'
import omico from '../../Assets/OmicoLogo/omico-logo-1.png'
import packageJson from '../../../package.json'
import { useAuth0 } from '@auth0/auth0-react'
import { MenuButtonIconProps, MenuButtonProps } from '../MenuButtonProps'
import { useQuery } from '@tanstack/react-query'
import { User } from '../../typescript/API'
import { AxiosResponse } from 'axios'
import MyApiService from '../../APIs/MyApi'
import { appBar } from '../../garvan-react/Constants/StyleConstants'
import UserMenuAvatar from '../../garvan-react/Components/Auth0Avatar'

function Header() {
  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0()
  let navigate = useNavigate()

  // can't get currentUser frm globalContext, as it is not loaded yet
  const { data: currentUser } = useQuery<User, AxiosResponse>(
    ['MyApiService', 'getCurrent'],
    () => MyApiService.getCurrent(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
    },
  )

  if (isAuthenticated && user) {
    return (
      <AppBar
        sx={{
          height: appBar,
          position: 'fixed',
          p: 1 / 5,
          justifyContent: 'center',
        }}
      >
        <Toolbar sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: { sm: 'none', xl: 'flex' }, flex: 1 }}>
            <ButtonBase
              onClick={() =>
                window.open(
                  'https://www.omico.com.au/',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              style={{ marginRight: '30px' }}
            >
              <img
                src={omico}
                alt="omico-logo"
                style={{ width: '80px' }}
              />
            </ButtonBase>
          </Box>
          <Box
            sx={{
              flex: 4,
              maxWidth: 'xl',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'center',
              width: 1,
            }}
          >
            <Box sx={{ display: 'flex', flex: 1 }}>
              <Tooltip
                title={
                  <Typography sx={{ fontSize: 13 }}>Find Patient</Typography>
                }
              >
                <IconButton
                  sx={{ ...MenuButtonProps }}
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => {
                    navigate('/frontend')
                  }}
                >
                  <Search sx={MenuButtonIconProps} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', textAlign: 'center' }}
              >
                {`${process.env?.REACT_APP_DESTINATION} ${packageJson.longName}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tooltip
                title={
                  <Typography style={{ fontSize: 13 }}>
                    Populate Tissue Molecular Screen
                  </Typography>
                }
                sx={{ p: 1 }}
              >
                <IconButton
                  sx={{ ...MenuButtonProps, ml: 'auto' }}
                  name="info"
                  color="inherit"
                  onClick={() => {
                    navigate('/frontend/popTMS')
                  }}
                >
                  <Description sx={MenuButtonIconProps} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Typography style={{ fontSize: 13 }}>Upload/Poll</Typography>
                }
                sx={{ p: 1 }}
              >
                <IconButton
                  sx={{ ...MenuButtonProps }}
                  name="info"
                  color="inherit"
                  onClick={() => {
                    navigate('/frontend/upload')
                  }}
                >
                  <CloudUpload sx={MenuButtonIconProps} />
                </IconButton>
              </Tooltip>
              {currentUser?.is_staff && (
                <Tooltip
                  title={
                    <Typography style={{ fontSize: 13 }}>Admin</Typography>
                  }
                  sx={{ p: 1 }}
                >
                  <IconButton
                    name="admin"
                    color="inherit"
                    onClick={() => {
                      window.open('/admin')
                    }}
                  >
                    <AdminPanelSettings sx={MenuButtonIconProps} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title={<Typography style={{ fontSize: 13 }}>About</Typography>}
                sx={{ p: 1 }}
              >
                <IconButton
                  sx={{ ...MenuButtonProps }}
                  name="info"
                  color="inherit"
                  onClick={() => {
                    navigate('/frontend/About')
                  }}
                >
                  <Info sx={MenuButtonIconProps} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Typography style={{ fontSize: 13 }}>Settings</Typography>
                }
                sx={{ p: 1 }}
              >
                <IconButton
                  sx={{ ...MenuButtonProps }}
                  name="info"
                  color="inherit"
                  onClick={() => {
                    navigate('/frontend/Settings')
                  }}
                >
                  <Settings sx={MenuButtonIconProps} />
                </IconButton>
              </Tooltip>
              <UserMenuAvatar user={user} logout={logout} showToken={true} getToken={getAccessTokenSilently}/>
            </Box>
          </Box>
          <Box sx={{ display: { sm: 'none', xl: 'flex' }, flex: 1 }} />
        </Toolbar>
      </AppBar>
    )
  } else {
    return (
      <AppBar
        sx={{
          height: appBar,
          position: 'fixed',
          p: 1 / 5,
          justifyContent: 'center',
        }}
      >
        <Toolbar>
          <Box sx={{ display: { sm: 'none', lg: 'flex' }, flex: 1 }}>
            <ButtonBase
              onClick={() =>
                window.open(
                  'https://www.omico.com.au/',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              style={{ marginRight: '30px' }}
            >
              <img
                src={omico}
                alt="omico-logo"
                style={{ width: '80px' }}
              />
            </ButtonBase>
          </Box>
          <Box
            sx={{
              flex: 4,
              maxWidth: 'xl',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              {`${process.env?.REACT_APP_DESTINATION} ${packageJson.longName}`}
            </Typography>
          </Box>
          <Box sx={{ flex: 1}}/>
        </Toolbar>
      </AppBar>
    )
  }
}

export default Header
