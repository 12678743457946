import React from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useGlobalContext } from '../LoadConfig'
import CannedCommentsTable from './CannedCommentsTable'

const Settings = () => {
  const { organisations, defaultOrg, setDefaultOrg } = useGlobalContext()

  return (
    <Box sx={{ maxWidth: 'md', width: 1 }}>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="h1">User Settings</Typography>
        </Box>
        <FormControl
          margin="normal"
          fullWidth
        >
          <InputLabel>Default Organisation</InputLabel>
          <Select
            label={'Default Organisation'}
            value={defaultOrg}
            onChange={(e) => {
              setDefaultOrg(e.target.value)
            }}
          >
            {organisations &&
              organisations.map((org) => (
                <MenuItem
                  value={org.id}
                  key={org.id}
                >
                  {org.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {organisations &&
          organisations.map((org) => <CannedCommentsTable org={org} />)}
      </Box>
    </Box>
  )
}

export default Settings
