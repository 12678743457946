import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DoubleArrow } from '@mui/icons-material'
import { flipTable } from './DynamicTables'
import {
  Box,
  Grid,
  GridSize,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import { usePatientContext } from '../../PatientLoad'
import CriteriaTable from './Tables/CriteriaTable'
import VariantsTable from './Tables/VariantsTable'
import ReportHistoryTable from './Tables/ReportHistoryTable'
import { useGlobalContext } from '../../LoadConfig'
import OtherTable from './Tables/OtherTable'
import SummaryTable from './Tables/SummaryTable'

function SortableTable({ index, id }: { id: string; index: number }) {
  const { molConfig } = useGlobalContext()

  const {
    molArchive,
    patientData,
    primaryTables,
    setPrimaryTables,
    secondaryTables,
    setSecondaryTables,
    loadingMolArchive,
  } = usePatientContext()

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: id,
  })

  let otherTableNames: string[] = []
  let summaryTableNames: string[] = []

  if (molConfig?.other_tables) {
    otherTableNames = molConfig.other_tables.map((table) => table.name)
  }
  if (molConfig?.summary_tables) {
    summaryTableNames = molConfig?.summary_tables
  }

  let size = 3
  if (index % 3 === 0) {
    size = 5
  } else if (index % 3 === 1) {
    size = 4
  }

  const maxHeight = 500

  function renderTable(tableName: string) {
    if (tableName === 'Criteria') return <CriteriaTable maxHeight={maxHeight} />
    if (tableName === 'Variants') return <VariantsTable maxHeight={maxHeight} />
    if (tableName === 'Report History')
      return ReportHistoryTable(maxHeight, patientData)
    if (otherTableNames.includes(tableName)) {
      const tableData = molArchive?.other_tables.filter(
        (table) => table.name === tableName,
      )[0]
      const tableConfig = molConfig?.other_tables.filter(
        (table) => table.name === tableName,
      )[0]
      return OtherTable(maxHeight, tableConfig, tableData, loadingMolArchive)
    }
    if (summaryTableNames.includes(tableName)) {
      return (
        <SummaryTable
          maxHeight={maxHeight}
          tableName={tableName}
        />
      )
    }
    if (tableName.includes('Image')) {
      const imageIndex = parseInt(tableName.split(' ')[1]) - 1
      const image = molArchive?.images?.[imageIndex]
      return (
        <Box sx={{ width: 1 }}>
          <Paper
            elevation={5}
            sx={{ padding: 1 / 2, display: 'flex', width: 1 }}
          >
            <img
              key={image}
              src={`data:image/png;base64,${image}`}
              alt="graph"
              style={{
                maxWidth: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxHeight: maxHeight,
              }}
            />
          </Paper>
        </Box>
      )
    }
    return <div />
  }

  return (
    <Grid
      key={id}
      ref={setNodeRef}
      style={{
        transform:
          transform ?
            CSS.Transform.toString({
              x: transform.x,
              y: transform.y,
              scaleX: 1,
              scaleY: 1,
            })
          : undefined,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 'auto',
      }}
      {...attributes}
      item
      sm={size as GridSize}
    >
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography
          sx={{ flex: 4, margin: 1 / 2 }}
          variant="h3"
        >
          {id}
        </Typography>
        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={() =>
            flipTable(
              secondaryTables,
              primaryTables,
              setSecondaryTables,
              setPrimaryTables,
              id,
            )
          }
        >
          <DoubleArrow />
        </IconButton>
      </Box>
      <Box
        {...listeners} // listens for drag action -> not applied to parent 'Grid' as will swallow above button
        sx={{
          display: 'flex',
          verticalAlign: 'top',
          width: 1,
          maxHeight: maxHeight,
        }}
      >
        {renderTable(id)}
      </Box>
    </Grid>
  )
}

export default SortableTable
