import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { HeaderCell } from '../../../../components/Table'
import CellFormat from '../CellFormat'
import React from 'react'
import TableLoading from '../TableLoading'
import { usePatientContext } from '../../../PatientLoad'
import TableAlert from '../TableAlert'

function SummaryTable({
  maxHeight,
  tableName,
}: {
  maxHeight: number
  tableName: string
}) {
  const { molArchive, loadingMolArchive } = usePatientContext()
  const tableData = molArchive?.summary?.attributes.filter(
    (table) => table.name === tableName,
  )[0]
  return (
    <Paper
      elevation={5}
      sx={{ width: 1 }}
    >
      <TableContainer sx={{ width: 1, maxHeight: maxHeight }}>
        <Table
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <HeaderCell>{tableName}</HeaderCell>
              <HeaderCell>{''}</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.attributes.map((row) => (
                <TableRow key={JSON.stringify(row)}>
                  <TableCell>{row.name}</TableCell>
                  <CellFormat value={row} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!molArchive && loadingMolArchive && <TableLoading />}
        {!molArchive && !loadingMolArchive && (
          <TableAlert message={'No Data'} />
        )}
      </TableContainer>
    </Paper>
  )
}

export default SummaryTable
