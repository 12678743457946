import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Box } from '@mui/material'

function HTMLEditor({
  value,
  setValue,
}: {
  value: string | undefined
  setValue: any
}) {
  const editorRef = useRef<any>(null)

  return (
    <Box
      onMouseLeave={() =>
        setValue((v: any) => {
          // FIXME - this is pretty horrible. 'value' is the email body only. But when calling setValue, we need to
          // deal with the whole email object, so we only set the body part here
          return { ...v, body: editorRef.current?.getContent() }
        })
      }
    >
      <Editor
        tinymceScriptSrc="/django/static/tinymce/js/tinymce/tinymce.min.js"
        onInit={(evt: any, editor: any) => (editorRef.current = editor)}
        initialValue={value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'table code codesample',
          ],
          toolbar:
            'undo redo ' +
            '| formatselect ' +
            '| bold italic underline removeformat ' +
            '| alignleft aligncenter alignright alignjustify ' +
            '| bullist numlist ' +
            '| outdent indent ' +
            '| table ' +
            '| link image ' +
            '| codesample ' +
            '| preview code ',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </Box>
  )
}

export default HTMLEditor
