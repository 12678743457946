import { DupeState } from '../../typescript/UI'
import { AlertColor } from '@mui/material'
import React from 'react'
import { RenderStatus } from '../RenderStatus'

function DuplicatePatientFeedback(status: DupeState | null) {
  let message
  let color: AlertColor
  let loading = false
  switch (status?.status) {
    case 'successful':
      color = 'success'
      message = 'Patient was successfully duplicated'
      break
    case 'failed':
      color = 'error'
      message = 'An error occurred during duplicating'
      break
    default:
      loading = true
      color = 'info'
      message = 'Duplication in progress...'
      break
  }

  return (
    <RenderStatus
      message={message}
      severity={color}
      loading={loading}
      description={JSON.stringify(status?.error?.data)}
    />
  )
}

export default DuplicatePatientFeedback
