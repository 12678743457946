//Sourced: https://intranet.gimr.garvan.org.au/display/COM/New+brand%2C+logos+and+templates
export const garvan_colours = {
  primary: {
    indigo: '#4D00C7',
    turquoise: '#02D5D2',
    grey: '#C6C7C5',
  },
  primary_accent: {
    orange: '#DB3C00',
  },
  neutrals: {
    dark_grey: '#E8E8E7',
    light_grey: '#F5F3F1',
    white: '#FFFFFF',
    black: '#000000',
  },
}

export const omico_colours = {
  primary: {
    white: '#FFFFFF',
    black: '#000000',
    pantone: '#B1B3B3',
  },
}
