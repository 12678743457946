import React, { ReactNode } from 'react'

import packageJson from '../../package.json'
import { Box, InputAdornment, Stack, Typography } from '@mui/material'
import { Architecture, DesignServices } from '@mui/icons-material'
import { useSysInfoContext } from './LoadSysInfo'
import { ReadOnlyTextField } from '../garvan-react/Components/ReadOnlyTextField'
import { stackGap } from '../Style&ThemeConstants'

const About = () => {
  const { sysInfo } = useSysInfoContext()

  function recursiveTextfields(
    value: { [key: string]: string },
    parent: string | null = null,
  ): ReactNode {
    return Object.entries(value).map(([key, value]) => {
      let label = key
      if (parent) label = parent + ' / ' + key
      if (value && typeof value === 'object' && Object.keys(value).length > 0) {
        return recursiveTextfields(value, label)
      } else {
        let displayValue = value
        if (typeof value === 'object') {
          displayValue = ''
        }
        return (
          <ReadOnlyTextField
            key={label}
            label={label}
            value={displayValue}
            fullWidth
          />
        )
      }
    })
  }

  let dependencies = ''
  Object.keys(packageJson.dependencies).forEach((item) => {
    dependencies = dependencies + item + '\n'
  })

  return (
    <Box
      sx={{
        maxWidth: 'md',
        width: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          p: 1,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: 800 }}
        >
          {`${process.env?.REACT_APP_DESTINATION} ${packageJson.shortName}`}
        </Typography>
        <Typography
          variant="h6"
          style={{ fontWeight: 300 }}
        >
          Omico
        </Typography>
      </Box>
      <Stack
        gap={stackGap.col}
        width={1}
      >
        <Stack
          direction={'row'}
          gap={stackGap.row}
          width={1}
        >
          <ReadOnlyTextField
            label="Environment"
            value={process.env.REACT_APP_UI_NAME}
            fullWidth
          />
          <ReadOnlyTextField
            label="Version"
            value={packageJson.version}
            fullWidth
          />
        </Stack>
        <Stack
          direction={'row'}
          gap={stackGap.row}
          width={1}
        >
          <ReadOnlyTextField
            label="Solutions Architect"
            value={'Matt Callow'}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Architecture />
                </InputAdornment>
              ),
            }}
          />
          <ReadOnlyTextField
            label="Frontend Developer"
            value={'Adrian Metlenko'}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DesignServices />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack
          direction={'row'}
          gap={stackGap.row}
          width={1}
        >
          <Stack
            gap={stackGap.col}
            sx={{ flex: 2 }}
          >
            {recursiveTextfields(sysInfo || {})}
          </Stack>
          <Box sx={{ flex: 1 }}>
            <ReadOnlyTextField
              label="Dependency Credits"
              value={dependencies.trimEnd()}
              multiline
              fullWidth
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

export default About
