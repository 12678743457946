import {
  Autocomplete,
  Box,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Assistant } from '@mui/icons-material'
import React, { useState } from 'react'
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { useGlobalContext } from '../../LoadConfig'

function CannedCommentAssistant(props: GridRenderEditCellParams) {
  const { id, value, field } = props
  const apiRef = useGridApiContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { cannedComments, organisations } = useGlobalContext()

  //items must be sorted by groupBy property (org)
  let cannedOptions =
    cannedComments && organisations ?
      cannedComments
        .map((option) => {
          //get org label
          const orgLabel = organisations.filter(
            (org) => org.id === option.org,
          )?.[0]?.name
          return { label: option.subject, id: option.comment, org: orgLabel }
        })
        .sort((a, b) => {
          return a.label < b.label ? 1 : -1
        })
        .sort((a, b) => {
          return a.org < b.org ? 1 : -1
        })
    : []

  return (
    <>
      <Tooltip
        title={
          <Typography sx={{ fontSize: 13 }}>{'Canned Comments'}</Typography>
        }
      >
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget as any)}
          color="inherit"
          sx={{ mt: 3 }}
        >
          <Assistant />
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ minWidth: '50vw', p: 1 }}>
          <Autocomplete
            fullWidth
            value={value || ''}
            onChange={(event: any, newValue) => {
              if (newValue) {
                //Triggered on canned list select
                apiRef.current.setEditCellValue({
                  id,
                  field,
                  value: newValue.id,
                })
                setAnchorEl(null)
              }
            }}
            onInputChange={(event, newInputValue) => {
              //Triggered on user type
              apiRef.current.setEditCellValue({
                id,
                field,
                value: newInputValue,
              })
            }}
            options={cannedOptions}
            groupBy={(option) => option.org}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                autoFocus
                error={
                  props?.value?.length !== undefined && props?.value?.length < 1
                }
              />
            )}
          />
        </Box>
      </Popover>
    </>
  )
}

export default CannedCommentAssistant
