import React, { createContext, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../APIs/MyApi'
import { useAuth0 } from '@auth0/auth0-react'

interface SysInfoContextType {
  sysInfo: any
}

export const SysInfoContext = createContext<SysInfoContextType>(
  {} as SysInfoContextType,
)
export const useSysInfoContext = () => useContext(SysInfoContext)

function SysInfoLoad({ children }: { children: any }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const { data: sysInfo } = useQuery<any, AxiosResponse>(
    ['MyApiService', 'getSysInfo'],
    () => MyApiService.getSysInfo(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
    },
  )

  return (
    <SysInfoContext.Provider
      value={{
        sysInfo,
      }}
    >
      {children}
    </SysInfoContext.Provider>
  )
}

export default SysInfoLoad
