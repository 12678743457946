import React from 'react'
import { TrialView } from '../../../typescript/API'
import { Box, Paper } from '@mui/material'
import OtherCard from './TrialCards/OtherCard'
import TrialCard from './TrialCards/TrialCard'

function TrialInfo({
  trial,
  locked,
}: {
  trial: TrialView
  locked: boolean | undefined
}) {
  return (
    <Box
      sx={{ m: 1, width: 'calc(100%-20px)' }}
      key={trial.uniq_id}
    >
      <Paper
        key={trial.uniq_id}
        sx={{
          width: 'calc(100%-10px)',
          p: 1 / 2,
          color: 'text.secondary',
          backgroundColor: locked ? 'grey.100' : 'background.paper',
        }}
      >
        {(
          !(
            trial.uniq_id.startsWith('trial') ||
            trial.uniq_id.startsWith('most')
          )
        ) ?
          <OtherCard trial={trial} />
        : <TrialCard
            trial={trial}
            most={trial.uniq_id.startsWith('most')}
          />
        }
      </Paper>
    </Box>
  )
}

export default TrialInfo
