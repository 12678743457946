import { Box, Paper, TableContainer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { AxiosResponse } from 'axios'
import CannedService from '../../APIs/MyApi_Canned'
import { useGlobalContext } from '../LoadConfig'
import {
  DataGridPro,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-pro'
import { cannedCommentsCols } from './CannedCommentsCols'
import DataGridToolbar from './DataGridToolbar'
import { DataGridActionCol } from '../DataGridActionCol'
import { useMessagePopupContext } from '../../garvan-react/Components/ModalFeedback/MessagePopup'

function CannedCommentsTable({ org }: { org: any }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const { setError } = useMessagePopupContext()
  const { refetchCanned, cannedComments } = useGlobalContext()

  const [rows, setRows] = useState<any[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    if (cannedComments) {
      setRows(cannedComments.filter((item) => item.org === org.id))
    }
  }, [cannedComments, org])

  const { mutate: addCanned } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(({ body }) => CannedService.addCanned(getAccessTokenSilently(), body), {
    onSuccess: () =>
      queryClient
        .invalidateQueries({ queryKey: ['CannedService'] })
        .then(() => refetchCanned()),
    onError: (err) => {
      setRows((r) => r.filter((row) => row.id !== 'new'))
      setError(err)
    },
  })

  const { mutate: updateCanned } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    ({ id, body }) =>
      CannedService.updateCanned(getAccessTokenSilently(), id, body),
    {
      onSuccess: () =>
        queryClient
          .invalidateQueries({ queryKey: ['CannedService'] })
          .then(refetchCanned()),
      onError: (err) => refetchCanned().then(setError(err)),
    },
  )

  const { mutate: deleteCanned } = useMutation<any, AxiosResponse, any>(
    ({ id }) => CannedService.deleteCanned(getAccessTokenSilently(), id),
    {
      onSuccess: () =>
        queryClient
          .invalidateQueries({ queryKey: ['CannedService'] })
          .then(refetchCanned()),
      onError: (err) => setError(err),
    },
  )

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteCanned({ id })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    if (id === 'new') {
      setRows((r) => r.filter((row) => row.id !== id))
    }
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    const body = {
      org: org.id,
      subject: newRow.subject,
      comment: newRow.comment,
    }
    if (newRow.id === 'new') {
      addCanned({ body })
    } else {
      updateCanned({
        body,
        id: newRow.id,
      })
    }
    return newRow
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    event.defaultMuiPrevented = true
  }

  return (
    <Box sx={{ width: 1 }}>
      <TableContainer
        component={Paper}
        sx={{ width: 1, minHeight: 100, mt: 2 }}
      >
        <DataGridPro
          rows={rows}
          loading={cannedComments === undefined}
          density={'compact'}
          autoHeight
          getRowHeight={() => 'auto'}
          disableColumnMenu
          editMode={'row'}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(n) => setRowModesModel(n)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'subject', sort: 'asc' }],
            },
          }}
          slots={{
            toolbar: DataGridToolbar,
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              title: `Canned Comments: ${org.name}`,
            },
          }}
          columns={[
            ...cannedCommentsCols,
            DataGridActionCol(
              rowModesModel,
              handleSaveClick,
              handleCancelClick,
              handleEditClick,
              handleDeleteClick,
            ),
          ]}
        />
      </TableContainer>
    </Box>
  )
}

export default CannedCommentsTable
