import client from './API_Config'
import { MolAction } from './Gorgon'
import { EmailTemplate } from '../typescript/API'

async function getPatient(accessToken: Promise<string>, patient_id: string) {
  return await client(await accessToken)
    .get<any>(`/myapi/patient/${patient_id}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getPatientInfoList(
  accessToken: Promise<string>,
  patient_ids: string[],
) {
  return await client(await accessToken)
    .post<any>(`/myapi/patient_info_list`, { patient_ids: patient_ids })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getCriteria(accessToken: Promise<string>, criteria_id: number) {
  return await client(await accessToken)
    .get<any>(`/myapi/criteria/${criteria_id}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function patchCriteria(
  accessToken: Promise<string>,
  criteria_id: number,
  payload: any,
) {
  return await client(await accessToken)
    .patch<any>(`/myapi/criteria/${criteria_id}/`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function putCriteria(
  accessToken: Promise<string>,
  criteria_id: number,
  payload: any,
) {
  return await client(await accessToken)
    .put<any>(`/myapi/criteria/${criteria_id}/`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getSysInfo(accessToken: Promise<string>) {
  return await client(await accessToken)
    .get<any>(`/myapi/sysinfo`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getUsers(accessToken: Promise<string>) {
  return await client(await accessToken)
    .get<any>(`/myapi/user`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getCurrent(accessToken: Promise<string>) {
  return await client(await accessToken)
    .get<any>(`/myapi/user/current`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getOrgs(accessToken: Promise<string>) {
  return await client(await accessToken)
    .get<any>(`/myapi/organisation`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getTaskStatus(accessToken: Promise<string>, task_id: number) {
  return await client(await accessToken)
    .get<any>(`/myapi/task/${task_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function pollData(accessToken: Promise<string>, body: any) {
  return await client(await accessToken)
    .post<any>(`/myapi/moldb/poll/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function sendAction(accessToken: Promise<string>, actions: MolAction[]) {
  return await client(await accessToken)
    .post<any>(`/myapi/moldb/async_request/`, { commands: actions })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getReport(accessToken: Promise<string>, patient_id: string) {
  return await client(await accessToken)
    .get<any>(`/myapi/mtb_report/${patient_id}`, { responseType: 'blob' })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getNataReportLink(
  accessToken: Promise<string>,
  patient_id: string,
) {
  return await client(await accessToken)
    .get<any>(`/myapi/nata_report_link/${patient_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getTrialSite(accessToken: Promise<string>, trial_id: string) {
  return await client(await accessToken)
    .get<any>(`/myapi/trialsite?trial_id=${trial_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}
async function getTrial(accessToken: Promise<string>, trial_id: string) {
  return await client(await accessToken)
    .get<any>(`/myapi/trial/${trial_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function sendEmail(
  accessToken: Promise<string>,
  patient_id: string,
  email: Partial<EmailTemplate>,
  current_stage: number | undefined,
) {
  return await client(await accessToken)
    .post<any>(
      `/myapi/email/${patient_id}?current_stage=${current_stage}`,
      email,
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function populateMolScreen(
  accessToken: Promise<string>,
  patient_id: string,
) {
  return await client(await accessToken)
    .post<any>(`/myapi/popTissueMolscreen/${patient_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function uploadFile(
  accessToken: Promise<string>,
  mtbRef: string,
  file: any,
) {
  let fd = new FormData()
  fd.append('file', file)
  fd.append('MTB Ref', mtbRef)

  return await client(await accessToken)
    .post<any>(`/myapi/upload`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const MyApiService = {
  getPatient,
  getPatientInfoList,
  getCriteria,
  getSysInfo,
  getUsers,
  getCurrent,
  getOrgs,
  getTaskStatus,
  pollData,
  sendAction,
  patchCriteria,
  getReport,
  getNataReportLink,
  sendEmail,
  populateMolScreen,
  uploadFile,
  putCriteria,
  getTrialSite,
  getTrial,
}

export default MyApiService
