import packageJson from '../../../../package.json'
import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { Location } from 'react-router-dom'
import { format } from 'date-fns'

export interface LocalError extends AxiosResponse {
  datetime?: string
  ui_v?: string
  api_v?: string
  name?: string
  location?: Location
}

export function transformError(
  error: LocalError | undefined,
  location: Location,
) {
  const datetime = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
  let localError = { ...error, datetime }

  // !! Keep user identification, but cleanse authorisation tokens !!
  // Output may be shared and copied insecurely
  if (localError?.config?.headers?.Authorization) {
    localError = {
      ...localError,
      config: {
        ...localError.config,
        headers: {
          ...localError.config.headers,
          Authorization: 'Bearer *****',
        } as AxiosRequestHeaders,
      },
    }
  }
  if (localError?.config?.params?.Token) {
    localError.config.params = {
      ...localError.config.params,
      Token: '*****',
    }
  }

  localError.name = packageJson.name
  localError.ui_v = packageJson.version
  localError.location = location
  return localError as LocalError
}
