import {
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import { Box, Button } from '@mui/material'
import { ChangeCircle } from '@mui/icons-material'
import React, { useState } from 'react'
import CommandModal from './Patient/CommandModal'

function SelectPatientToolbar(selectionModel: GridRowSelectionModel) {
  const [openCommands, setOpenCommands] = useState(false)

  return (
    <GridToolbarContainer sx={{ display: 'flex', p: 1 }}>
      <Box sx={{ display: 'flex', width: 1, m: 1 / 2 }}>
        <GridToolbarFilterButton />
        <Button
          color="primary"
          startIcon={<ChangeCircle />}
          onClick={() => setOpenCommands(true)}
          sx={{ ml: 'auto', display: 'flex' }}
          variant={'outlined'}
        >
          Commands
        </Button>
      </Box>
      <CommandModal
        commandList={'batch'}
        openCommands={openCommands}
        setOpenCommands={setOpenCommands}
        selectionModel={selectionModel}
      />
    </GridToolbarContainer>
  )
}

export default SelectPatientToolbar
