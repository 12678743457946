import { Organisation } from '../typescript/API'

export function getOrgName(
  organisations: Organisation[] | undefined,
  id: number,
) {
  return organisations ?
      organisations.filter((org) => org.id === id)?.[0]?.name
    : ''
}
