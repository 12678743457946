import React from 'react'
import PatientLoad from './PatientLoad'
import PatientBar from './PatientBar'
import Duplicate from './Patient/Duplicate/Duplicate'
import PatientTrialsAndTherapies from './Patient/Trials&Therapies/TrialsAndTherapies'
import PatientFinalise from './Patient/Finalise/Finalise'
import PatientOther from './Patient/Summary&Other/Other'
import PatientSummary from './Patient/Summary&Other/Summary'
import { Navigate, Route, Routes } from 'react-router-dom'

function Patient() {
  return (
    <PatientLoad>
      <PatientBar />
      <Routes>
        <Route
          path="duplicate"
          element={<Duplicate />}
        />
        <Route path="latest_ranking">
          <Route
            index
            element={
              <Navigate
                to={'summary'}
                replace
              />
            }
          />
          <Route
            path="trialsandtherapies"
            element={<PatientTrialsAndTherapies />}
          />
          <Route
            path="finalise"
            element={<PatientFinalise />}
          />
          <Route
            path="other"
            element={<PatientOther />}
          />
          <Route
            path="summary"
            element={<PatientSummary />}
          />
        </Route>
      </Routes>
    </PatientLoad>
  )
}

export default Patient
