import React, { useState } from 'react'
import { Alert, AlertTitle, Box, TextField, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { Description } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../APIs/MyApi'
import { LoadingButton } from '@mui/lab'
import { useMessagePopupContext } from '../garvan-react/Components/ModalFeedback/MessagePopup'

const PopTMS = () => {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()

  const [knum, setKnum] = useState('')
  const [success, setSuccess] = useState<any>({})

  const { mutate: populateMolScreen, isLoading: loadingMolScreen } =
    useMutation<any, AxiosResponse>(
      () => MyApiService.populateMolScreen(getAccessTokenSilently(), knum),
      {
        onError: (err) => setError(err),
        onSuccess: (data) => setSuccess(data),
      },
    )

  return (
    <Box sx={{ maxWidth: 'md', width: 1 }}>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1">Populate Tissue Molecular Screen</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            label="Patient KNUM"
            value={knum}
            onChange={(e) => {
              setKnum(e.target.value)
              setSuccess({})
            }}
            margin="normal"
          />
          <LoadingButton
            variant="contained"
            disabled={knum.length === 0}
            onClick={() => populateMolScreen()}
            startIcon={<Description />}
            loading={loadingMolScreen}
          >
            Populate
          </LoadingButton>
        </Box>
        {Object.keys(success).length > 0 && (
          <Alert
            severity={'success'}
            sx={{ my: 1 }}
          >
            <AlertTitle>Operation Successful</AlertTitle>
            {success?.Status}
          </Alert>
        )}
      </Box>
    </Box>
  )
}

export default PopTMS
