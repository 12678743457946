import client from './API_Config'

async function getAuditLogs(
  accessToken: Promise<string>,
  model: string,
  patient_id: string | number | undefined,
) {
  return await client(await accessToken)
    .get<any>(
      `/audit/logentry?app=myapi&model=${model}&patient_id=${patient_id}`,
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const AuditApiService = {
  getAuditLogs,
}

export default AuditApiService
