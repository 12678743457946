import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { blue, green, orange, red } from '@mui/material/colors'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import TrialInfo from './TrialInfo'
import { usePatientContext } from '../../PatientLoad'

const colourLookup = (grp_name: string) => {
  switch (grp_name) {
    case 'excluded':
      return red
    case 'top':
      return green
    case 'other':
      return blue
    default:
      return orange
  }
}

const newNames = (grp_name: string) => {
  switch (grp_name) {
    case 'top':
      return 'Report'
    case 'other':
      return 'Extended'
    default:
      return 'Excluded'
  }
}

function RenderTOE({
  value,
  otherKey,
  locked,
}: {
  value: string
  otherKey: string
  locked: boolean | undefined
}) {
  const { toeData } = usePatientContext()

  return (
    //Needed to prevent lower box from maxHeight
    <Box
      sx={{
        mx: 2,
        flex: 1,
      }}
    >
      <Paper
        elevation={5}
        sx={{
          backgroundColor: colourLookup(value)['100'],
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          overflow: 'auto',
        }}
        key={value}
      >
        <Box
          sx={{
            backgroundColor: colourLookup(value)['600'],
            color: '#FFFFFF',
            fontWeight: 'bold',
            padding: 1,
          }}
        >
          <Typography variant="h3">{newNames(value)}</Typography>
        </Box>
        {toeData && (
          <Droppable droppableId={value}>
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                sx={{ overflow: 'auto', maxHeight: '60vh' }}
              >
                {toeData[otherKey][value].length > 0 || locked ?
                  toeData[otherKey][value].map((item, index) => (
                    <Draggable
                      isDragDisabled={locked}
                      key={item.uniq_id}
                      draggableId={item.uniq_id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style }}
                        >
                          <TrialInfo
                            trial={item}
                            locked={locked}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))
                : <Box sx={{ width: 1 }}>
                    <Box
                      style={{ borderStyle: 'dashed' }}
                      sx={{
                        m: 1,
                        width: 'calc(100%-60px)',
                        p: 2,
                        color: 'text.secondary',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',

                        border: 1,
                        borderRadius: 3,
                      }}
                    >
                      <Typography variant="body1">Dropzone</Typography>
                    </Box>
                  </Box>
                }
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </Paper>
    </Box>
  )
}

export default RenderTOE
