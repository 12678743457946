import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import { IconButton, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import React from 'react'

function NoteToolbar({ setMode, title }: { setMode: any; title: string }) {
  return (
    <GridToolbarContainer>
      <Typography
        sx={{ fontWeight: 'bold', ml: 1 }}
        variant={'subtitle1'}
      >
        {title}
      </Typography>
      <IconButton
        sx={{ ml: 'auto', color: 'inherit' }}
        onClick={() => setMode('add')}
      >
        <Add />
      </IconButton>
    </GridToolbarContainer>
  )
}

export default NoteToolbar
