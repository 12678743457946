import { finaliseStages } from './FinaliseStage'

export function interpretAuditChange(change: string) {
  const changes = JSON.parse(change)
  return Object.entries(changes)
    .map(([change, state]) => {
      return stringifyChange(change, state)
    })
    .join(', ')
}

export function stringifyChange(change: string, state: any) {
  const from = state?.[0]
  const to = state?.[1]
  switch (change) {
    case 'workflow_status':
      return `Moved from ${finaliseStages[parseInt(state[0]) - 1]?.stage ?? 'Initial'} to ${finaliseStages[parseInt(state[1]) - 1]?.stage}`
    case 'assigned_user':
      return `Assignee changed from ${from === 'None' ? 'None' : from.replace(/ \(.*\)/, '')} to ${to === 'None' ? 'None' : to.replace(/ \(.*\)/, '')}`
    case 'patient':
      return ''
    default:
      return `Change: Key["${change}"], State"[${state}]"`
  }
}
