import React from 'react'
import { RenderStatus } from './RenderStatus'

function NotFound() {
  return (
    <RenderStatus
      message={'Patient Not Found'}
      loading={false}
    />
  )
}

export default NotFound
