import { Box, Chip, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { TrialView } from '../../../typescript/API'
import { green } from '@mui/material/colors'

export const drugChip = (value: string) => {
  if (value === '') return

  //need to split drugs as some are very long but chip height doesn't match wrapping behaviour
  //reversing to use .pop function
  const drugLines = value.split(' + ').reverse()

  let drugLinesSplit = []
  let line = ''
  while (drugLines.length > 0) {
    line = drugLines.pop()!
    if (
      drugLines.length === 0 ||
      line.length > 30 ||
      (drugLines.length > 0 &&
        line.length + drugLines[drugLines.length - 1].length > 30)
    ) {
      drugLinesSplit.push(line)
    } else {
      const lineTwo = drugLines.pop()!
      drugLinesSplit.push(line + ' + ' + lineTwo)
    }
  }

  let label = <Box>{drugLinesSplit[0]}</Box>
  if (drugLinesSplit.length > 1) {
    label = (
      <Box>
        {drugLinesSplit.slice(0, drugLinesSplit.length - 1).map((line) => (
          <Box key={line}>{line + ' +'}</Box>
        ))}
        <Box>{drugLinesSplit[drugLinesSplit.length - 1]}</Box>
      </Box>
    )
  }

  return (
    <Chip
      key={value}
      size="small"
      label={label}
      style={{
        margin: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        height: 20 * drugLinesSplit.length + (drugLinesSplit.length - 1) * 2,
        borderRadius: 8,
      }}
    />
  )
}

export const tmcRender = (trial: TrialView) => {
  const mapping = {
    // "drug class sensitivity": {"color": "blue", "symbol": "C"},
    // "drug sensitivity": {"color": "green", "symbol": "D"},
    drug: { color: 'blue', symbol: 'D', tooltip: 'drug/drug class matched' },
    'cancer type': { color: 'red', symbol: 'H', tooltip: 'histotype matched' },
  }

  let tmc = []
  for (const [key, value] of Object.entries(mapping)) {
    if (trial.trial_match_criteria.includes(key)) {
      const item = (
        <Tooltip
          key={`${trial.uniq_id}-${value['symbol']}`}
          title={<h2 style={{ fontSize: 14 }}>{value['tooltip']}</h2>}
        >
          <Box style={{ display: 'inline-block' }}>
            <Typography
              variant="body2"
              sx={{ color: value['color'] }}
            >
              {value['symbol']}
            </Typography>
          </Box>
        </Tooltip>
      )
      tmc.push(item)
    }
  }

  // add in the is_ineligible
  if (trial.is_ineligible !== '') {
    const item = (
      <Tooltip
        key={`${trial.uniq_id}-X`}
        title={<h2 style={{ fontSize: 14 }}>{trial.is_ineligible}</h2>}
      >
        <Box style={{ display: 'inline-block' }}>
          <Typography variant="body2">X</Typography>
        </Box>
      </Tooltip>
    )
    tmc.push(item)
  }

  if (trial.locmatch === 'true') {
    const item = (
      <Tooltip
        key={`${trial.uniq_id}-L`}
        title={<h2 style={{ fontSize: 14 }}>{'Location matches (state)'}</h2>}
      >
        <div style={{ display: 'inline-block' }}>
          <Typography
            variant="body2"
            sx={{ color: green }}
          >
            L
          </Typography>
        </div>
      </Tooltip>
    )
    tmc.push(item)
  }

  return tmc
}
