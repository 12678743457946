import {
  Box,
  Button,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import useInterval from '../../Hooks/useInterval'
import { getOrgName } from '../../functions/GetOrgName'
import { Download, Refresh } from '@mui/icons-material'
import { useGlobalContext } from '../LoadConfig'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../../APIs/MyApi'
import { useMessagePopupContext } from '../../garvan-react/Components/ModalFeedback/MessagePopup'

function PollData(mtbRef: string) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { setError } = useMessagePopupContext()

  const { organisations, defaultOrg } = useGlobalContext()

  const [jobId, setJobId] = useState<number | null>(null)
  const [errorText, setErrorText] = useState('')

  const {
    data: taskStatus,
    refetch,
    error,
  } = useQuery<any, AxiosResponse>(
    ['MyApiService', 'getTaskStatus', jobId],
    () => MyApiService.getTaskStatus(getAccessTokenSilently(), jobId!),
    {
      enabled: isAuthenticated && !!jobId,
      onError: (err) => {
        setError(err)
        setErrorText(err.statusText)
      },
    },
  )

  const maxPolls = 30 * 5
  const secondInterval = 2
  const [pollsRemain, setPollsRemain] = useState(maxPolls)

  useInterval(async () => {
    if (!error && jobId && pollsRemain > 0 && taskStatus?.running) {
      setPollsRemain((t) => t - 1)
      await refetch()
    }
  }, secondInterval * 1000) // to seconds

  const { mutate: pollData } = useMutation<any, AxiosResponse, any, () => void>(
    () =>
      MyApiService.pollData(getAccessTokenSilently(), {
        org: getOrgName(organisations, defaultOrg),
        'MTB Ref': mtbRef,
        category: category,
      }),
    {
      onSuccess: (data) => setJobId(data.job_id),
      onError: (error) => setErrorText(`${error.status}: ${error.statusText}`),
    },
  )

  const [category, setCategory] = useState('External')
  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          my: 1,
          width: '1',
        }}
      >
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          sx={{ minWidth: 300 }}
        >
          {['All', 'External', 'Internal'].map((item) => (
            <MenuItem
              value={item}
              key={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant={'contained'}
          onClick={pollData}
          disabled={!organisations || !defaultOrg}
          sx={{ m: 2 }}
          startIcon={<Download />}
        >
          Pull Data
        </Button>
        {errorText && (
          <FormHelperText
            error
            sx={{ fontSize: 16, mt: 1 }}
          >
            {errorText}
          </FormHelperText>
        )}
        <Button
          variant={'contained'}
          disabled
          sx={{ m: 1 / 2 }}
          startIcon={<Refresh />}
          size={'small'}
        >
          Refresh Patient List
        </Button>
      </Box>
      {taskStatus?.running && <LinearProgress sx={{ width: 1, mt: 2 }} />}
      {taskStatus &&
        !taskStatus?.running &&
        taskStatus?.finished_ok &&
        taskStatus?.result?.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{ width: 1, mt: 2 }}
          >
            <Table sx={{ width: 1 }}>
              <TableBody>
                {taskStatus.result.map((row: string) => (
                  <TableRow key={row}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {row}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Box>
  )
}

export default PollData
