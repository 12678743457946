import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Redo } from '@mui/icons-material'
import { MenuButtonIconProps } from '../../MenuButtonProps'
import { usePatientContext } from '../../PatientLoad'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios/index'
import RankTrialService from '../../../APIs/MyApi_RankTrials'
import { enqueueSnackbar } from 'notistack'

function TTRedoButton() {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()

  const { rankData, criteriaData, refetchRank, refetchPatient } =
    usePatientContext()

  const { mutate: redoRanking, isLoading: redoLoading } = useMutation<
    any,
    AxiosResponse,
    string | number,
    () => void
  >(
    (criteriaId) =>
      RankTrialService.redoRanking(getAccessTokenSilently(), {
        criteria_id: criteriaId,
      }),
    {
      onSuccess: (data) => {
        enqueueSnackbar('Successfully Re-Ranked', { variant: 'success' })
        refetchRank(data)
        refetchPatient()
      },
      onError: (err) => setError(err),
    },
  )

  return (
    <Tooltip
      title={<Typography sx={{ fontSize: 13 }}>Redo Ranking</Typography>}
    >
      <span>
        <IconButton
          sx={{ mx: 1 }}
          color="inherit"
          onClick={() => redoRanking(criteriaData?.id!)}
          disabled={!rankData?.workflow_status || rankData.workflow_status > 2}
        >
          {redoLoading ?
            <CircularProgress />
          : <Redo sx={MenuButtonIconProps} />}
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default TTRedoButton
