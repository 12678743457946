import React from 'react'

import { AppBar, Box, Typography } from '@mui/material'
import { appBar, envBar } from '../Constants/StyleConstants'
import { envColour } from '../Functions/EnvColour'

function EnvBar({
  env,
  leftOffset = 0,
  topOffset = appBar,
  repeat = true,
  bgColor = envColour(),
}: {
  env: string | undefined
  leftOffset?: number
  topOffset?: number
  repeat?: boolean
  bgColor?: string
}) {
  return (
    <AppBar
      aria-label={'Env Bar'}
      sx={{
        mt: topOffset / 10,
        pl: leftOffset / 10,
        height: envBar,
        flexGrow: 1,
        zIndex: 1090,
        position: 'fixed',
        bgcolor: bgColor,
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {repeat ?
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
            <Typography
              key={i}
              variant="h6"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'white',
                minWidth: 500,
                width: 1,
              }}
            >
              {env}
            </Typography>
          ))
        : <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'white',
              width: 1,
            }}
          >
            {env}
          </Typography>
        }
      </Box>
    </AppBar>
  )
}

export default EnvBar
