import { EmailTemplate } from '../../../typescript/API'

export function emailInvalid(email: Partial<EmailTemplate>) {
  return (
    !email?.subject ||
    !email?.body ||
    !email?.to ||
    email.to.length < 1 ||
    !email.to[0]
  )
}
