export interface FinaliseStage {
  stage: string
  caption: string
  transitionNext?: string
}

export const finaliseStages: FinaliseStage[] = [
  {
    stage: 'Variant Curation',
    caption: '',
    transitionNext: 'Variant sign off',
  },
  {
    stage: 'Trials Curation',
    caption: '',
    transitionNext: 'Clinical sign off',
  },
  {
    stage: 'Final Check',
    caption: 'Prepare Email',
    transitionNext: 'Mark as Sent',
  },
  {
    stage: 'Sent',
    caption: '',
  },
]
