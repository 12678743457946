import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { HeaderCell } from '../../../../components/Table'
import { v4 as uuid } from 'uuid'
import CellFormat from '../CellFormat'
import React from 'react'
import { OtherTableType } from '../../../../typescript/API'
import TableLoading from '../TableLoading'

function OtherTable(
  maxHeight: number,
  molConfig: { name: string; attributes: string[] } | undefined,
  tableData: OtherTableType | undefined,
  loading: boolean,
) {
  return (
    <Paper
      elevation={5}
      sx={{ width: 1 }}
    >
      <TableContainer sx={{ width: 1, maxHeight: maxHeight }}>
        <Table
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {molConfig &&
                molConfig.attributes.map((col) => (
                  <HeaderCell key={uuid()}>{col}</HeaderCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              molConfig &&
              tableData.attributes.map((row) => (
                <TableRow key={uuid()}>
                  {molConfig.attributes.map((item) => (
                    <CellFormat
                      key={uuid()}
                      value={row?.[item]}
                    />
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {loading && <TableLoading />}
      </TableContainer>
    </Paper>
  )
}

export default OtherTable
