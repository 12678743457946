import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import About from './containers/About'
import Settings from './containers/Settings/Settings'
import Upload from './containers/Upload/Upload'
import NotAuthorised from './containers/NotAuthorised'
import NotFound from './containers/NotFound'
import Patient from './containers/Patient'
import SelectPatient from './containers/SelectPatient'
import PopTMS from './containers/PopTMS'
import RequiredAuth from './Auth/RequiredAuth'

function Router() {
  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={'frontend'}
            replace
          />
        }
      />
      <Route
        path="frontend"
        element={<RequiredAuth />}
      >
        <Route
          index
          element={<SelectPatient />}
        />
        <Route
          path="about"
          element={<About />}
        />
        <Route
          path="settings"
          element={<Settings />}
        />
        <Route
          path="upload"
          element={<Upload />}
        />
        <Route
          path="popTMS"
          element={<PopTMS />}
        />
        <Route
          path="not_authorised"
          element={<NotAuthorised />}
        />
        <Route
          path="not_found"
          element={<NotFound />}
        />
        <Route
          path="index.html"
          element={<SelectPatient />}
        />
        <Route
          path=":patient_id/*"
          element={<Patient />}
        />
      </Route>
    </Routes>
  )
}

export default Router
