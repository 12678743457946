import axios from 'axios'

const client = (access_token: string) =>
  (() => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
      cache: 'no-cache',
    }
    return axios.create({
      baseURL: window.location.origin + '/',
      headers: headers,
    })
  })()

export default client
