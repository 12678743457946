export const MenuButtonProps = {
  color: '#FFFFFF',
  '& .Mui-disabled': {
    color: '#000000 !important',
  },
}

export const MenuButtonIconProps = {
  width: 30,
  height: 30,
  m: 1 / 2,
}
