import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../Hooks/useLocalStorage'
import { CriteriaData, MolArchive, PatientData, RankTrials, TOE_View } from '../typescript/API'
import { Json } from '../typescript/GenericTypes'
import usePatientId from '../Hooks/usePatientId'
import CommandModal from './Patient/CommandModal'
import { AxiosResponse } from 'axios'
import MyApiService from '../APIs/MyApi'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import GorgonService from '../APIs/Gorgon'
import RankTrialService from '../APIs/MyApi_RankTrials'
import { useMessagePopupContext } from '../garvan-react/Components/ModalFeedback/MessagePopup'

interface PatientContextType {
  patientData: PatientData | undefined
  refetchPatient: any
  patientLoading: boolean
  molArchive: MolArchive | undefined
  fetchMolArchive: any

  criteriaData: CriteriaData | undefined | null
  refetchCriteria: any

  setTierRankingMode: any
  tierRankingMode: Json | undefined

  rankData: RankTrials | undefined | null
  refetchRank: any
  toeData: TOE_View | undefined | null
  refetchTOE: any

  allMutations: boolean
  setAllMutations: any

  loadingMolArchive: boolean

  primaryTables: string[]
  setPrimaryTables: any

  secondaryTables: string[]
  setSecondaryTables: any

  openCommands: boolean
  setOpenCommands: any
  toeLoading: boolean
  rankLoading: boolean
  criteriaLoading: boolean
}

export const PatientContext = createContext<PatientContextType>(
  {} as PatientContextType,
)
export const usePatientContext = () => useContext(PatientContext)

function PatientLoad({ children }: { children: any }) {
  const navigate = useNavigate()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const { setError, setMessage } = useMessagePopupContext()
  const patientId = usePatientId()

  const [primaryTables, setPrimaryTables] = useLocalStorage(
    'primaryPatientTables',
    [],
  )
  const [secondaryTables, setSecondaryTables] = useLocalStorage(
    'secondaryPatientTables',
    [],
  )

  const [tierRankingMode, setTierRankingMode] = useState<string | undefined>(
    undefined,
  )

  const {
    data: patientData,
    refetch: refetchPatient,
    isLoading: patientLoading,
  } = useQuery<PatientData, AxiosResponse>(
    ['MyApiService', 'getPatient', patientId],
    () => MyApiService.getPatient(getAccessTokenSilently(), patientId!),
    {
      enabled: isAuthenticated && !!patientId,
      onError: (err) => {
        if (err?.status === 403) {
          navigate('/frontend/not_authorised', { replace: true })
        } else if (err?.status === 404) {
          navigate('/frontend/not_found', { replace: true })
        } else {
          setError(err)
        }
      },
    },
  )

  const [molArchive, setMolArchive] = useState<MolArchive | undefined>(
    undefined,
  )
  const [openCommands, setOpenCommands] = useState(false)
  const [allMutations, setAllMutations] = useState(false)

  const { mutate: getMolArchive, isLoading: loadingMolArchive } = useMutation<
    any,
    AxiosResponse,
    boolean,
    () => void
  >(
    (all) =>
      GorgonService.sendAction(getAccessTokenSilently(), [
        {
          action: 'get',
          table: 'mol_formatted',
          sel: {
            criteria: { Patient: patientData?.patient_id },
            opts: { all: all, version: '2.0' },
          },
        },
      ]),
    {
      onSuccess: (data) => {
        const response = GorgonService.checkResponse(data)
        if (response.valid) {
          if (!response.ok || response.response?.data?.success === false) {
            setMessage({
              message: 'Unable to get formatted patient data',
              severity: 'warning',
              subject: 'Unexpected Error',
            })
          } else {
            setMolArchive(response.response.data[0])
          }
        } else {
          setMessage({
            message: 'Unable to get formatted patient data - response invalid',
            severity: 'warning',
            subject: 'Unexpected Error',
          })
        }
      },
      onError: (err) => setError(err),
    },
  )

  const fetchMolArchive = useMemo(() => {
    if (patientData) {
      return () => getMolArchive(allMutations)
    }
    return () => undefined
  }, [patientData, getMolArchive, allMutations])

  useEffect(() => {
    fetchMolArchive()
  }, [patientData, fetchMolArchive])

  const {
    data: criteriaData,
    refetch: refetchCriteria,
    isLoading: criteriaLoading,
  } = useQuery<CriteriaData, AxiosResponse>(
    ['MyApiService', 'getCriteria', patientData?.criteria_id!],
    () =>
      MyApiService.getCriteria(
        getAccessTokenSilently(),
        patientData?.criteria_id!,
      ),
    {
      enabled: isAuthenticated && !!patientData?.criteria_id,
      onError: (err) => setError(err),
      onSuccess: (data) => {
        let trm = 'pedantic'
        if ('tier_ranking_mode' in data.variants) {
          trm = data.variants['tier_ranking_mode'] as string
        }
        setTierRankingMode(trm)
      },
      select: (data) => {
        if (typeof data.variants === 'string') {
          const variants = JSON.parse(data.variants)
          // save variants as JSON
          return { ...data, variants: variants }
        } else {
          return data
        }
      },
    },
  )

  const {
    data: rankData,
    refetch: refetchRank,
    isLoading: rankLoading,
  } = useQuery<RankTrials, AxiosResponse>(
    ['RankTrialService', 'getRank', patientData?.rank_id!],
    () =>
      RankTrialService.getRank(getAccessTokenSilently(), patientData?.rank_id!),
    {
      enabled: isAuthenticated && !!patientData?.rank_id,
      onError: (err) => setError(err),
    },
  )

  const {
    data: toeData,
    refetch: refetchTOE,
    isLoading: toeLoading,
  } = useQuery<RankTrials, AxiosResponse>(
    ['RankTrialService', 'getTOE', patientData?.rank_id!],
    () =>
      RankTrialService.getTOE(getAccessTokenSilently(), patientData?.rank_id!),
    {
      enabled: isAuthenticated && !!patientData?.rank_id,
      onError: (err) => setError(err),
    },
  )

  //SET Tables
  //IF table positions are defined in local storage, use
  //ELSE add table to Primary Tab
  useEffect(() => {
    if (molArchive?.hasOwnProperty('Analysis ID')) {
      const specialTables = ['Criteria', 'Variants', 'Report History']
      let otherTables: string[] = []
      let summaryTables: string[] = []
      let images: string[] = []
      if (molArchive?.other_tables) {
        otherTables = molArchive.other_tables.map((table) => table.name)
      }
      if (molArchive?.summary?.attributes !== undefined) {
        summaryTables = molArchive.summary.attributes.map((table) => table.name)
      }
      if (molArchive?.images !== undefined) {
        images = molArchive.images.map((item, index) => 'Image ' + (index + 1))
      }
      const allTables = otherTables
        .concat(summaryTables)
        .concat(images)
        .concat(specialTables)
      //existing tables are saved locally, so we check what is new
      const newTables = allTables.filter(
        (item) =>
          !secondaryTables.includes(item) && !primaryTables.includes(item),
      )
      if (newTables.length > 0) {
        const newPrimary = primaryTables.concat(newTables)
        setPrimaryTables(newPrimary)
      }
    }
  }, [molArchive, primaryTables, secondaryTables, setPrimaryTables])

  return (
    <PatientContext.Provider
      value={{
        patientData,
        refetchPatient,
        patientLoading,
        molArchive,
        fetchMolArchive,

        criteriaData,
        refetchCriteria,

        setTierRankingMode,
        tierRankingMode,

        rankData,
        refetchRank,
        toeData,
        refetchTOE,

        allMutations,
        setAllMutations,

        loadingMolArchive,

        primaryTables,
        setPrimaryTables,

        secondaryTables,
        setSecondaryTables,

        openCommands,
        setOpenCommands,
        toeLoading,
        rankLoading,
        criteriaLoading,
      }}
    >
      <CommandModal
        commandList={'patient'}
        openCommands={openCommands}
        setOpenCommands={setOpenCommands}
      />
      {children}
    </PatientContext.Provider>
  )
}

export default PatientLoad
