import React from 'react'
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  LinearProgress,
  Typography,
} from '@mui/material'

export const RenderStatus = ({
  message,
  loading = true,
  severity = 'error',
  description,
}: {
  message: string | undefined
  loading?: boolean
  severity?: AlertColor
  description?: string
}) => {
  return (
    <Box sx={{ p: 2, maxWidth: 'sm', width: 1 }}>
      <Alert
        severity={severity}
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
        }}
      >
        <Box sx={{ width: 1 }}>
          <AlertTitle>
            <Typography variant={'h3'}>{message}</Typography>
          </AlertTitle>
          {description}
          {loading && (
            <LinearProgress
              color={severity}
              sx={{ mt: 2 }}
            />
          )}
        </Box>
      </Alert>
    </Box>
  )
}
