import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { ContentCopy, ExitToApp } from '@mui/icons-material'

function UserMenuAvatar({
  user,
  logout,
  getToken,
  showToken = false,
}: {
  user: any
  logout?: any
  showToken?: boolean
  getToken?: any
}) {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  return (
    <>
      <Tooltip title={<Typography style={{ fontSize: 13 }}>User</Typography>}>
        <IconButton
          aria-label={'Auth Avatar'}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="small"
        >
          <Avatar
            src={user?.picture}
            sx={{ width: 40, height: 40 }}
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ maxWidth: '300px' }}
      >
        <MenuItem
          aria-label={'User Name'}
          disabled
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          {user?.name}
        </MenuItem>
        <MenuItem
          disabled
          sx={{ textAlign: 'center' }}
        >
          {user?.email}
        </MenuItem>
        {((showToken && getToken) || logout) && <Divider />}
        {showToken && getToken && (
          <MenuItem
            aria-label={'Copy Token'}
            onClick={() => {
              getToken().then(async (accessToken: string) => {
                await navigator.clipboard.writeText(accessToken)
              })
            }}
          >
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy Token</ListItemText>
          </MenuItem>
        )}
        {logout && (
          <MenuItem
            aria-label={'Logout'}
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
          >
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default UserMenuAvatar
