import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useGlobalContext } from '../LoadConfig'
import React, { useEffect, useMemo, useState } from 'react'
import { MolCommand } from '../../typescript/API'
import GorgonService from '../../APIs/Gorgon'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../../APIs/MyApi'
import useInterval from '../../Hooks/useInterval'
import usePatientId from '../../Hooks/usePatientId'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useMessagePopupContext } from '../../garvan-react/Components/ModalFeedback/MessagePopup'

function CommandModal({
  commandList,
  openCommands,
  setOpenCommands,
  selectionModel,
}: {
  commandList: 'batch' | 'patient'
  openCommands: boolean
  setOpenCommands: any
  selectionModel?: GridRowSelectionModel
}) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { molConfig } = useGlobalContext()
  const { setError } = useMessagePopupContext()

  let commands: MolCommand[] = useMemo(() => {
    if (commandList === 'batch' && molConfig?.batch_commands) {
      return molConfig.batch_commands
    } else if (molConfig?.patient_commands) {
      return molConfig.patient_commands
    } else {
      return []
    }
  }, [molConfig, commandList])

  interface Feedback {
    message: string
    severity: AlertColor
  }

  const [command, setCommand] = useState<any>('')
  const [feedback, setFeedback] = useState<Feedback | undefined>(undefined)
  const [jobId, setJobId] = useState<number | null>(null)
  const patientId = usePatientId()

  const selectedCommand: MolCommand | undefined = useMemo(() => {
    return commands?.filter((c) =>
      command?.command ?
        c.command === JSON.parse(command?.command)?.[0]
      : false,
    )?.[0]
  }, [command?.command, commands])

  useEffect(() => {
    if (selectedCommand?.patient_list && selectionModel) {
      const plist = selectionModel.map(
        (p) => /^([A-Z][0-9]+)/g.exec(p as string)?.[1],
      )
      setCommand((c: any) => {
        return { ...c, patient_list: plist }
      })
    }
  }, [setCommand, selectedCommand, selectionModel, patientId])

  useEffect(() => {
    if (patientId) {
      setCommand((c: any) => {
        if (c && !c?.patient_list) {
          return { ...c, patient_list: [patientId] }
        } else {
          return c
        }
      })
    }
  }, [setCommand, patientId, command])

  const {
    mutate: sendSyncAction,
    error: syncError,
    isLoading: syncLoading,
    reset: resetSync,
  } = useMutation<any, AxiosResponse>(
    () => GorgonService.sendAction(getAccessTokenSilently(), [command]),
    {
      onSuccess: (data) => {
        const response = GorgonService.checkResponse(data)
        setFeedback({
          message:
            response.response?.data?.error ??
            response.response?.data?.message ??
            'Unable to trigger sync for unknown reason',
          severity: response.ok ? 'success' : 'error',
        })
      },
      onError: (err) => setError(err),
    },
  )

  const {
    mutate: sendAsyncAction,
    error: asyncError,
    reset: resetAsync,
  } = useMutation<JobStatus, AxiosResponse>(
    () => MyApiService.sendAction(getAccessTokenSilently(), [command]),
    {
      onSuccess: (data) => {
        if (data?.job_id) {
          setJobId(data?.job_id)
        }
      },
      onError: (err) => setError(err),
    },
  )

  interface JobStatus {
    status: string
    job_id: number
  }

  const {
    data: taskStatus,
    refetch,
    error: statusError,
  } = useQuery<any, AxiosResponse>(
    ['MyApiService', 'getTaskStatus', jobId],
    () => MyApiService.getTaskStatus(getAccessTokenSilently(), jobId!),
    {
      enabled: isAuthenticated && !!jobId,
      onError: (err) => setError(err),
    },
  )

  const maxPolls = 30 * 5
  const secondInterval = 2
  const [pollsRemain, setPollsRemain] = useState(maxPolls)

  useInterval(async () => {
    if (
      !statusError &&
      jobId &&
      pollsRemain > 0 &&
      taskStatus?.running !== false
    ) {
      setPollsRemain((t) => t - 1)
      await refetch()
    }
  }, secondInterval * 1000) // to seconds

  const error =
    command && JSON.parse(command?.command)[1] === 'async' ?
      asyncError
    : syncError

  return (
    <Dialog open={openCommands}>
      <DialogTitle>Patient Commands</DialogTitle>
      <DialogContent sx={{ minHeight: 200 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl margin="normal">
            <InputLabel>Command</InputLabel>
            <Select
              label={'Command'}
              value={command?.command || ''}
              onChange={(e) => {
                setCommand((c: any) => {
                  return {
                    ...c,
                    command: e.target.value,
                    action: JSON.parse(e.target.value)[0],
                  }
                })
                setFeedback(undefined)
                resetAsync()
                resetSync()
              }}
            >
              {commands &&
                commands.map((c) => (
                  <MenuItem
                    value={JSON.stringify([c.command, c.mode])}
                    key={c.command}
                    disabled={c.patient_list && selectionModel?.length === 0}
                  >
                    {`${c.command} [${c.mode}]`}
                  </MenuItem>
                ))}
            </Select>
            {selectionModel?.length === 0 && (
              <FormHelperText>
                Some commands require patients to be selected
              </FormHelperText>
            )}
          </FormControl>
          {selectedCommand?.parameters &&
            selectedCommand.parameters.map((name) => (
              <TextField
                key={name}
                label={name}
                value={command?.[name] || ''}
                onChange={(e) =>
                  setCommand((c: any) => {
                    return { ...c, [name]: e.target.value }
                  })
                }
                margin="normal"
              />
            ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{ mt: 1 }}
              variant={'contained'}
              disabled={!command?.command || !!syncError}
              onClick={() => {
                if (JSON.parse(command?.command)[1] === 'async') {
                  setFeedback({
                    message:
                      commands?.filter(
                        (c) => c.command === JSON.parse(command?.command)?.[0],
                      )?.[0]?.message || '',
                    severity: 'info',
                  })
                  sendAsyncAction()
                } else {
                  sendSyncAction()
                }
              }}
            >
              Run Command
            </Button>
          </Box>
          {error && (
            <Box sx={{ mt: 2 }}>
              <Alert severity={'error'}>
                <Box
                  sx={{
                    width: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {'Status:'}
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography>{error?.status}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {'Message:'}
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography>{error?.statusText}</Typography>
                  </Box>
                  {error?.data?.detail && (
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {'Detail:'}
                      </Typography>
                      &nbsp;&nbsp;
                      <Typography>{error?.data?.detail}</Typography>
                    </Box>
                  )}
                </Box>
              </Alert>
            </Box>
          )}
          {(feedback || syncLoading) && (
            <Box sx={{ mt: 2 }}>
              <Alert
                severity={syncLoading ? 'info' : feedback?.severity}
                sx={{
                  '& .MuiAlert-message': {
                    width: '100%',
                  },
                }}
              >
                <AlertTitle>
                  {' '}
                  {syncLoading ? 'Command is Running' : feedback?.message}
                </AlertTitle>
                {syncLoading && <LinearProgress color={'info'} />}
              </Alert>
            </Box>
          )}
          {taskStatus && (
            <Box sx={{ mt: 2 }}>
              <Alert
                severity={taskStatus?.result?.success ? 'success' : 'info'}
                sx={{
                  '& .MuiAlert-message': {
                    width: '100%',
                  },
                }}
              >
                <AlertTitle>{taskStatus?.result?.message}</AlertTitle>
                {taskStatus?.running && <LinearProgress color={'info'} />}
              </Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenCommands(false)}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CommandModal
