export function setParam(key: string, value: string, setSearchParams: any) {
  // const url = new URL(window.location.href);
  // url.searchParams.set(key, value)
  // window.history.pushState({}, '', url);

  setSearchParams((p: any) => {
    p.set([key], value)
    return p
  })
}

export function deleteParam(key: string, setSearchParams: any) {
  // const url = new URL(window.location.href);
  // url.searchParams.delete(key)
  // window.history.pushState({}, '', url);

  setSearchParams((p: any) => {
    p.delete(key)
    return p
  })
}
