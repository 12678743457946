import client from './API_Config'

async function getRank(accessToken: Promise<string>, rank_id: string) {
  return await client(await accessToken)
    .get<any>(`/myapi/ranktrials/${rank_id}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getTOE(accessToken: Promise<string>, rank_id: string) {
  return await client(await accessToken)
    .get<any>(`/myapi/ranktrials/${rank_id}/toe_view/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

export type rankStage = 'next_stage' | 'previous_stage'

async function updateStage(
  accessToken: Promise<string>,
  rank_id: string,
  update: rankStage,
  current_stage: number | undefined,
) {
  return await client(await accessToken)
    .post<any>(
      `/myapi/ranktrials/${rank_id}/${update}/?current_stage=${current_stage}`,
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function redoRanking(accessToken: Promise<string>, body: any) {
  return await client(await accessToken)
    .post<any>(`/myapi/ranktrials/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function updateRanking(
  accessToken: Promise<string>,
  rank_id: string,
  TOE_VIEW: any,
) {
  return await client(await accessToken)
    .put<any>(`/myapi/ranktrials/${rank_id}/`, {
      TOE: TOE_VIEW, //Key must be "TOE" if passing /TOE_VIEW structure
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const RankTrialService = {
  getRank,
  getTOE,
  updateStage,
  updateRanking,
  redoRanking,
}

export default RankTrialService
