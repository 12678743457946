import client from './API_Config'

async function getTemplates(accessToken: Promise<string>) {
  return await client(await accessToken)
    .get<any>(`/myapi/email_template`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function getTemplate(
  accessToken: Promise<string>,
  template_id: number,
  patient_id: string,
) {
  return await client(await accessToken)
    .get<any>(`/myapi/email_template/${template_id}?PNum=${patient_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const EmailService = {
  getTemplates,
  getTemplate,
}

export default EmailService
