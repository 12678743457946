import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import NoteService from '../../../APIs/MyApi_Note'
import { useAuth0 } from '@auth0/auth0-react'
import { usePatientContext } from '../../PatientLoad'
import usePatientId from '../../../Hooks/usePatientId'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { LoadingButton } from '@mui/lab'

function NoteDialogue({ open, setOpen }: { open: boolean; setOpen: any }) {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const patientId = usePatientId()
  const [localNote, setLocalNote] = useState<any>({})

  const { criteriaData } = usePatientContext()

  const { mutate: addNote, isLoading } = useMutation<any, AxiosResponse>(
    () =>
      NoteService.addNote(getAccessTokenSilently(), criteriaData?.id!, {
        text: localNote.text.trim(),
      }),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries({
            queryKey: ['NoteService', 'getNotes', patientId],
          })
          .then(() => {
            setOpen(false)
            setLocalNote({})
          })
      },
      onError: (err) => setError(err),
    },
  )

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        setLocalNote({})
      }}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 1000,
        },
      }}
    >
      <DialogTitle>Add Note</DialogTitle>
      <DialogContent sx={{ minWidth: 800 }}>
        <TextField
          sx={{ mt: 1 }}
          label={'Note'}
          value={localNote?.text || ''}
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          onChange={(e) =>
            setLocalNote((n: any) => {
              return { ...n, text: e.target.value }
            })
          }
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!localNote?.text || localNote.text.trim().length === 0}
          loading={isLoading}
          onClick={() => addNote()}
          variant={'contained'}
          color="success"
        >
          Add
        </LoadingButton>
        <Button
          onClick={() => {
            setOpen(false)
            setLocalNote({})
          }}
          color="error"
          variant={'outlined'}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NoteDialogue
