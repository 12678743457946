import client from './API_Config'

export interface MolAction {
  action: string

  [key: string]: any
}

async function sendAction(accessToken: Promise<string>, actions: MolAction[]) {
  return await client(await accessToken)
    .post<any>(`/8010/`, { commands: actions })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

export interface successResponse {
  data: any
  received: 'ok'
  success: boolean
}

export interface errorResponse {
  error: string
  success: boolean
  message: string
}

function checkResponse(data: successResponse | errorResponse): {
  valid: boolean
  ok: boolean
  response?: any
  message?: string
} {
  if ('error' in data || ('success' in data?.data && !data?.data?.success)) {
    return { valid: true, ok: false, response: data }
  } else if ('received' in data) {
    return { valid: true, ok: true, response: data }
  } else {
    console.error('Unexpected response data returned')
    return {
      valid: false,
      ok: false,
      message: 'Unexpected response data returned',
    }
  }
}

const GorgonService = {
  sendAction,
  checkResponse,
}

export default GorgonService
