import {
  GridActionsCellItem,
  GridActionsColDef,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro'
import { Cancel, Delete, Edit, Save } from '@mui/icons-material'
import React from 'react'

export function DataGridActionCol(
  rowModesModel: GridRowModesModel,
  handleSaveClick: any,
  handleCancelClick: any,
  handleEditClick: any,
  handleDeleteClick: any,
  editDisabled = false,
): GridActionsColDef {
  return {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id, row }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<Save />}
            label="Save"
            color="inherit"
            disabled={editDisabled}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<Cancel />}
            label="Cancel"
            disabled={editDisabled}
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ]
      }

      return [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          disabled={editDisabled}
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          disabled={editDisabled}
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ]
    },
  }
}
