import { Box, CircularProgress } from '@mui/material'
import React from 'react'

function TableLoading() {
  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
      <CircularProgress sx={{ m: 2 }} />
    </Box>
  )
}

export default TableLoading
