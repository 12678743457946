import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../APIs/MyApi'
import { PatientInfo } from '../typescript/Patient'
import { useGlobalContext } from './LoadConfig'
import { useAuth0 } from '@auth0/auth0-react'
import { useMessagePopupContext } from '../garvan-react/Components/ModalFeedback/MessagePopup'

interface SelectPatientContextType {
  sortModel: GridSortModel
  filterModel: GridFilterModel
  setSortModel: any
  setFilterModel: any
  isLoading: boolean
  patientRows: any[]
}

export const SelectPatientContext = createContext<SelectPatientContextType>(
  {} as SelectPatientContextType,
)
export const useSelectPatientContext = () => useContext(SelectPatientContext)

function SelectPatientLoad({ children }: { children: any }) {
  const { recentPatients } = useGlobalContext()
  const { setError } = useMessagePopupContext()

  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  //SelectPatient state models located here to persist throughout session
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })

  const {
    data: patientInfoList,
    mutate: getPatientInfoList,
    isLoading: loadingPatientInfoList,
  } = useMutation<any, AxiosResponse, string[], () => void>(
    (patient_ids) =>
      MyApiService.getPatientInfoList(getAccessTokenSilently(), patient_ids),
    {
      onError: (err) => setError(err),
    },
  )

  useEffect(() => {
    if (isAuthenticated && recentPatients?.attributes) {
      getPatientInfoList(recentPatients?.attributes.map((p) => p.Patient))
    }
  }, [getPatientInfoList, recentPatients?.attributes, isAuthenticated])

  const patientRows = useMemo(() => {
    if (recentPatients?.attributes) {
      return recentPatients.attributes.map((p: any) => {
        let info = {} as PatientInfo
        if (patientInfoList) {
          info = patientInfoList.filter(
            (i: any) => i.patient.patient_id === p.Patient,
          )?.[0]
        }
        setSortModel([{ field: 'workflow_status', sort: 'desc' }])
        return {
          ...p,
          id: p.Patient + '-' + p['MTB Ref'],
          workflow_status: info?.workflow_status,
          assigned_user_name:
            info?.assigned_user ?
              `${info?.assigned_user?.first_name} ${info?.assigned_user?.last_name}`
            : '',
        }
      })
    } else {
      return []
    }
  }, [recentPatients?.attributes, patientInfoList, setSortModel])

  return (
    <SelectPatientContext.Provider
      value={{
        sortModel,
        filterModel,
        setSortModel,
        setFilterModel,
        isLoading:
          recentPatients?.attributes === undefined || loadingPatientInfoList,
        patientRows,
      }}
    >
      {children}
    </SelectPatientContext.Provider>
  )
}

export default SelectPatientLoad
