import { Box, FormHelperText, TextField } from '@mui/material'
import { format, isValid } from 'date-fns'
import React from 'react'

export function MTBRefInput(
  newRef: string,
  setNewRef: any,
  newRefValid: boolean,
  setNewRefValid: any,
) {
  return (
    <Box py={1}>
      <TextField
        variant="outlined"
        value={newRef}
        label="MTB Ref"
        onChange={(e) => setNewRef(e.target.value)}
        onBlur={(e) => {
          //root must be valid date, but string suffix allowed
          const rawInput = e.target.value
          const date = rawInput.slice(0, 10)
          const suffix = rawInput.slice(10, 100)
          setNewRefValid(isValid(new Date(date)))
          if (isValid(new Date(date))) {
            setNewRef(format(new Date(date), 'yyyy-MM-dd') + suffix)
          }
        }}
        fullWidth
        autoFocus
        error={!newRefValid}
      />
      {!newRefValid && (
        <FormHelperText error>You have entered an invalid Date</FormHelperText>
      )}
    </Box>
  )
}
