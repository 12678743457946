import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>,
  document.getElementById('root'),
)

// TODO: Below fixes above deprectaion, but does not warn of max depth recursion bug https://jira.gimr.garvan.org.au/browse/GCMP-581
// let ReactDOM = require('react-dom/client');
// const container = document.getElementById('root');
// const root = ReactDOM.createRoot(container as HTMLElement);
//
// const app = (
//     <StrictMode>
//         <HelmetProvider>
//             <Helmet>
//                 <title>{packageJson.name}</title>
//             </Helmet>
//             <Router>
//                 <App/>
//             </Router>
//         </HelmetProvider>
//     </StrictMode>
// );
//
// root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
