import { garvan_colours, omico_colours } from './Colours'

export const garvanPalette = {
  primary: { main: garvan_colours.primary.indigo },
  secondary: { main: garvan_colours.primary.turquoise },
}

export const omicoPalette = {
  primary: { main: omico_colours.primary.black },
  secondary: { main: omico_colours.primary.pantone },
}

export const defaultTheme = {
  typography: {
    h1: {
      //page titles
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 2,
    },
    h2: {
      //page sub-titles
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      //app bar
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      //
    },
    h5: {
      //
    },
    h6: {
      //
    },
    subtitle2: {
      // Card titles
      fontWeight: 800,
    },
    body2: {
      // Card info
    },
  },
  spacing: 10,

  // Using default as custom breaks grid sizes
  // breakpoints: {
  //     values: {
  //         xs: 0,
  //         sm: 600, = Mobile
  //         md: 900, = Tablet
  //         lg: 1200, = Laptop
  //         xl: 1536, = Desktop
  //     },
  // },
}
