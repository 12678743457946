//heights
export const appBar = 80
export const envBar = 40
export const footer = 30

//widths
export const drawer = 250

//menu icon buttons
export const MenuButtonIconProps = {
  width: 30,
  height: 30,
  m: 1 / 2,
}
