import React, { createContext, useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import RenderMessage, { RenderMessageInterface } from './RenderMessage'
import RenderError from './RenderError'
import { LocalError } from './TransformError'

interface MessagePopupContextType {
  setError: (error: undefined | LocalError) => any
  setMessage: (message: RenderMessageInterface) => any
  error: undefined | LocalError
  message: RenderMessageInterface
}

export const MessagePopupContext = createContext<MessagePopupContextType>(
  {} as MessagePopupContextType,
)
export const useMessagePopupContext = () => useContext(MessagePopupContext)

function MessagePopup({
  children,
  errorSound,
  api_v,
}: {
  children: any
  api_v?: string
  errorSound?: any
}) {
  const [error, setError] = useState<LocalError | undefined>(undefined)
  const [message, setMessage] = useState<any>(undefined)

  return (
    <MessagePopupContext.Provider
      value={{
        setError,
        setMessage,
        error,
        message,
      }}
    >
      {children}
      <Dialog open={!!message}>
        <DialogContent sx={{ p: 1, pb: 0 }}>
          <RenderMessage message={message} />
        </DialogContent>
        <DialogActions>
          <Button
            aria-label={'Close'}
            onClick={() => setMessage(undefined)}
            color={message?.severity ?? 'success'}
            variant={'outlined'}
          >
            OK, Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!error}>
        <DialogContent sx={{ p: 1, pb: 0 }}>
          <Box sx={{ minWidth: 400, width: 1 }}>
            <RenderError
              error={
                {
                  ...error,
                  api_v,
                } as LocalError
              }
              errorSound={errorSound}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label={'Close'}
            onClick={() => setError(undefined)}
            color={message?.severity ?? 'error'}
          >
            OK, Close
          </Button>
        </DialogActions>
      </Dialog>
    </MessagePopupContext.Provider>
  )
}

export default MessagePopup
