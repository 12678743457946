import React from 'react'
import { v4 as uuid } from 'uuid'
import { Badge, Button, TableCell, Tooltip, Typography } from '@mui/material'

function tooltipValue(value: string, fullValue: string) {
  if (fullValue) {
    return (
      <Tooltip title={<Typography variant={'body1'}>{fullValue}</Typography>}>
        <Badge
          color="secondary"
          badgeContent="..."
        >
          <Typography>{value}</Typography>
        </Badge>
      </Tooltip>
    )
  } else {
    return <Typography variant={'body2'}>{value}</Typography>
  }
}

function replaceNotUndefined(oldValue: any, newValue: any) {
  if (newValue !== undefined) {
    return newValue
  } else {
    return oldValue
  }
}

export default function CellFormat(props: { [key: string]: any }) {
  let color = props?.color
  let url = props?.url
  let value = props?.value
  let fullValue = null

  //value = {value: 'x', color: 'x', url: 'x', full_value: 'x'}
  if (typeof value === 'object') {
    fullValue = replaceNotUndefined(fullValue, value?.full_value)
    color = replaceNotUndefined(color, value?.color)
    url = replaceNotUndefined(url, value?.url)
    value = replaceNotUndefined(value, value?.value) //last as overriding initial obj
  }

  if (url) {
    return (
      <TableCell
        style={{ backgroundColor: color, padding: 0 }}
        key={value}
      >
        <Button
          onClick={() => {
            window.open(url, '_blank')
          }}
          style={{ width: '100%', height: '100%', textAlign: 'start' }}
        >
          {tooltipValue(value, fullValue)}
        </Button>
      </TableCell>
    )
  } else {
    return (
      <TableCell
        style={{ backgroundColor: color }}
        key={value ? value : uuid()}
      >
        {tooltipValue(value, fullValue)}
      </TableCell>
    )
  }
}
