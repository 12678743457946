import client from './API_Config'

async function getComments(accessToken: Promise<string>, criteria_id: number) {
  return await client(await accessToken)
    .get<any>(`/myapi/comment/?criteria=${criteria_id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function deleteComment(accessToken: Promise<string>, comment_id: number) {
  return await client(await accessToken)
    .delete<any>(`/myapi/comment/${comment_id}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function updateComment(
  accessToken: Promise<string>,
  comment_id: number,
  body: any,
) {
  return await client(await accessToken)
    .patch<any>(`/myapi/comment/${comment_id}/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function addComment(accessToken: Promise<string>, body: any) {
  return await client(await accessToken)
    .post<any>(`/myapi/comment/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const CommentService = {
  getComments,
  deleteComment,
  updateComment,
  addComment,
}

export default CommentService
