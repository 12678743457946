import { Alert, AlertColor, Box, Typography } from '@mui/material'
import React from 'react'

function TableAlert({
  message,
  severity = 'warning',
}: {
  message: string
  severity?: AlertColor
}) {
  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
      <Alert
        severity={severity}
        sx={{ m: 1 }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{message}</Typography>
      </Alert>
    </Box>
  )
}

export default TableAlert
