import { pBar } from './Home/Home'
import { AppBar, Box, Chip, IconButton, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import usePatientId from '../Hooks/usePatientId'
import {
  AssignmentTurnedIn,
  ChangeCircle,
  Contacts,
  FileCopy,
  ListAlt,
  Lock,
  LockOpen,
  PermContactCalendar,
  Place
} from '@mui/icons-material'
import { MenuButtonIconProps, MenuButtonProps } from './MenuButtonProps'
import { usePatientContext } from './PatientLoad'
import { useLocation, useNavigate } from 'react-router-dom'
import { appBar } from '../garvan-react/Constants/StyleConstants'

function PatientBar() {
  const theme = useTheme()

  const patientId = usePatientId()
  const { setOpenCommands, rankData, molArchive } = usePatientContext()
  const navigate = useNavigate()
  const pathname = useLocation().pathname

  const patientMenuButton = (
    title: string,
    icon: any,
    action: any,
    disabled: boolean,
  ) => {
    return (
      <Tooltip title={<Typography sx={{ fontSize: 12 }}>{title}</Typography>}>
        <span>
          <IconButton
            onClick={() => action()}
            sx={MenuButtonProps}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  // GCMP-627
  let clinicalData: any = {}
  let postCode = ''
  let state = ''
  let attribute
  if (molArchive?.summary?.attributes) {
    attribute = molArchive?.summary?.attributes.filter(
      (a) => a.name === 'Clinical Data',
    )
    if (attribute.length === 1) {
      clinicalData = attribute[0]
    }
  }
  if (clinicalData?.attributes) {
    attribute = clinicalData?.attributes.filter(
      (a: any) => a.name === 'Post Code',
    )
    if (attribute?.length === 1) {
      postCode = attribute[0].value
    }
    attribute = clinicalData?.attributes.filter((a: any) => a.name === 'State')
    if (attribute?.length === 1) {
      state = attribute[0].value
    }
  }

  const lockIcon =
    !rankData?.workflow_status || rankData.workflow_status > 2 ?
      <Lock sx={{ ...MenuButtonIconProps, ml: 1 }} />
    : <LockOpen sx={{ ...MenuButtonIconProps, ml: 1 }} />

  return (
    <AppBar
      sx={{
        mt: appBar / 10,
        height: pBar,
        flexGrow: 1,
        zIndex: 1090,
        position: 'fixed',
        bgcolor: theme.palette.secondary.main,
        justifyContent: 'center',
      }}
    >
      <Toolbar sx={{ display: 'flex', flexDirection: 'row', width: 1 }}>
        <Box sx={{ display: 'flex', flex: 1 }} />
        <Box
          sx={{
            flex: 4,
            maxWidth: 'xl',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            width: 1,
          }}
        >
          <Box sx={{ flex: 1, justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {patientMenuButton(
                'Summary',
                <PermContactCalendar sx={MenuButtonIconProps} />,
                () => navigate('latest_ranking/summary/'),
                pathname.includes('/latest_ranking/summary/'),
              )}
              {patientMenuButton(
                'Trials & Therapies',
                <ListAlt sx={MenuButtonIconProps} />,
                () => navigate('latest_ranking/trialsandtherapies/'),
                pathname.includes('/latest_ranking/trialsandtherapies/'),
              )}
              {patientMenuButton(
                'Finalise Summary',
                <AssignmentTurnedIn sx={MenuButtonIconProps} />,
                () => navigate('latest_ranking/finalise/'),
                pathname.includes('/latest_ranking/finalise/'),
              )}
              {patientMenuButton(
                'Other Info',
                <Contacts sx={MenuButtonIconProps} />,
                () => navigate('latest_ranking/other/'),
                pathname.includes('/latest_ranking/other/'),
              )}
              {patientMenuButton(
                'Duplicate',
                <FileCopy sx={MenuButtonIconProps} />,
                () => navigate('duplicate/'),
                pathname.includes('/duplicate/'),
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              {patientId && patientId}
            </Typography>
            <Chip
              icon={<Place sx={{ color: 'white !important' }} />}
              label={
                <span>
                  <strong>{state}</strong> {postCode}
                </span>
              }
              variant={'filled'}
              sx={{ color: 'inherit', ml: 1 }}
            />
            {lockIcon}
          </Box>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Box sx={{ display: 'flex', ml: 'auto' }} />
            {patientMenuButton(
              'Commands',
              <ChangeCircle sx={MenuButtonIconProps} />,
              () => setOpenCommands(true),
              !(
                pathname.includes('/latest_ranking/summary') ||
                pathname.includes('/latest_ranking/other')
              ),
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }} />
      </Toolbar>
    </AppBar>
  )
}

export default PatientBar
