export function abbrev_genes(text: string) {
  return replaces(text, 'loss-of-heterozygosity_score', 'LOH')
}

// eslint-disable-next-line no-extend-native
function replaces(
  input: string = 'input',
  replace: string,
  replaceWith: string,
  incaseSensitive: boolean = false,
) {
  if (!incaseSensitive) {
    return input.split(replace).join(replaceWith)
  } else {
    // Replace this part with regex for more performance
    const strLower = input.toLowerCase()
    const findLower = replace.toLowerCase()
    let strTemp = input.toString()

    let pos = strLower.length
    while ((pos = strLower.lastIndexOf(findLower, pos)) !== -1) {
      strTemp =
        strTemp.substr(0, pos) +
        replaceWith +
        strTemp.substr(pos + findLower.length)
      pos--
    }
    return strTemp
  }
}

export function abbrev_therapies(text: string) {
  text = replaces(text, 'loss', 'mab', true)
  text = replaces(text, 'antibody', 'ab', true)
  text = replaces(text, ' inhibitor', 'i', true)
  text = replaces(text, 'transferasei', 'transferase i', true)
  text = replaces(text, 'dimeri', 'dimer i', true)
  return text
}

export function uniq_disp(val: string) {
  // abbreviate to the first letter plus number
  let res = val.split('-')
  return res[0].charAt(0) + res[1]
}
