import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  ArrowForward,
  CheckCircle,
  Clear,
  Download,
  Send,
  Undo,
} from '@mui/icons-material'
import { useGlobalContext } from '../../LoadConfig'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { usePatientContext } from '../../PatientLoad'
import usePatientId from '../../../Hooks/usePatientId'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import RankTrialService from '../../../APIs/MyApi_RankTrials'
import { AxiosResponse } from 'axios'
import { EmailTemplate } from '../../../typescript/API'
import PrepareEmail from './PrepareEmail'
import { LoadingButton } from '@mui/lab'
import MyApi from '../../../APIs/MyApi'
import MyApiService from '../../../APIs/MyApi'
import { finaliseStages } from './FinaliseStage'
import EmailService from '../../../APIs/MyApi_Email'
import { emailInvalid } from './ValidateEmail'
import PDFCustomText from './PDFCustomText'
import Notes from './Notes'
import { useNavigate } from 'react-router-dom'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'

type stageDirection = 'next' | 'previous'
const Finalise = () => {
  const patientId = usePatientId()
  const queryClient = useQueryClient()
  const { usersData, currentUser } = useGlobalContext()

  const { criteriaData, refetchRank, patientData, rankData, refetchCriteria } =
    usePatientContext()

  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { setError, setMessage } = useMessagePopupContext()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { data: nataReportLink } = useQuery<any, AxiosResponse>(
    // FIXME - handle errors more gracefully
    // If there is a 404 error, that means there is no report, so nata_report_link should be blank
    // That will hide the button, which is what we want. But we should handle other errors
    ['MyApiService', 'getNataReportLink', patientId!],
    () => MyApiService.getNataReportLink(getAccessTokenSilently(), patientId!),
    {
      select: (data) => {
        return data.nata_report_link
      },
    },
  )

  const usersList =
    usersData ?
      usersData.map((user) => (
        <MenuItem
          value={user.id}
          key={user.id}
        >
          {user.first_name !== '' || user.last_name !== '' ?
            user.first_name + ' ' + user.last_name
          : user.username}
        </MenuItem>
      ))
    : []

  const { mutate: patchCriteria } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    (criteria) =>
      MyApiService.patchCriteria(
        getAccessTokenSilently(),
        criteriaData!.id,
        criteria,
      ),
    {
      onSuccess: () => {
        enqueueSnackbar('Changes Saved!', {
          autoHideDuration: 1500,
          variant: 'success',
        })
        refetchCriteria()
      },
      onError: (err) => setError(err),
    },
  )

  const { mutate: updateRankTrialStage, isLoading: stageLoading } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    (params) =>
      RankTrialService.updateStage(
        getAccessTokenSilently(),
        patientData!.rank_id,
        params.rankStage,
        params.currentWorkflowStatus,
      ),
    {
      onSuccess: async (response, params) => {
        if (
          params.currentWorkflowStatus === 2 &&
          params.rankStage === 'next_stage'
        ) {
          setMessage({
            subject: 'Please Note',
            message:
              'The operation has been started and will complete in the background. The record will be updated once it completes',
            severity: 'info',
          })
          navigate('/')
        } else {
          refetchRank()
          await queryClient.invalidateQueries({
            queryKey: ['RankTrialService', 'getRank', patientData?.rank_id!],
          })
          await queryClient.invalidateQueries({
            queryKey: [
              'MyApiService',
              'getCriteria',
              patientData?.criteria_id!,
            ],
          })
        }
      },
      onError: (err) => setError(err),
      onSettled: () => setDirection(undefined),
    },
  )

  const [templateId, setTemplateId] = useState<number | null>(null)
  const [email, setEmail] = useState<Partial<EmailTemplate>>({})

  useQuery<EmailTemplate, AxiosResponse>(
    ['EmailService', 'getTemplate', templateId, patientId],
    () =>
      EmailService.getTemplate(
        getAccessTokenSilently(),
        templateId!,
        patientId!,
      ),
    {
      enabled: isAuthenticated && !!templateId && !!patientId,
      onSuccess: (data) => setEmail(data),
      onError: (err) => setError(err),
    },
  )

  const { mutate: sendEmail, isLoading: emailLoading } = useMutation<
    any,
    AxiosResponse,
    number
  >(
    (currentWorkflowStatus) =>
      MyApi.sendEmail(
        getAccessTokenSilently(),
        patientId!,
        email,
        currentWorkflowStatus,
      ),
    {
      onSuccess: (data) => {
        refetchRank()
        if (data.warning) {
          setMessage({
            subject: 'An error ocurred sending the notification',
            message: data.warning,
            severity: 'warning',
          })
        }
      },
      onError: (err) => setError(err),
      onSettled: () => setDirection(undefined),
    },
  )

  const { mutate: getReport, isLoading: reportLoading } = useMutation<
    any,
    AxiosResponse
  >(() => MyApi.getReport(getAccessTokenSilently(), patientId!), {
    onSuccess: (data) => {
      try {
        let blob = new Blob([data], { type: 'application/pdf' })

        // Previously 'Download' - See GCMP-583
        // let link = window.document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.target = '_blank'
        //
        // const dateTime = Date.now()
        // const timestamp = Math.floor(dateTime / 1000)
        //
        // link.download = `${patientId}_${criteriaData?.id!}_${patientData?.rank_id}_${timestamp}.pdf`
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link)

        let fileURL = URL.createObjectURL(blob)
        window.open(fileURL, '_blank')
      } catch (e) {
        setMessage({
          message: 'An uncaught error ocurred trying to open the file',
          severity: 'warning',
          subject: 'Unexpected Error',
        })
      }
    },
    onError: (err) => setError(err),
  })

  //https://www.tiny.cloud/docs/integrations/react/

  function stepLabel(current: number | undefined, move: stageDirection) {
    if (current && rankData?.workflow_status) {
      if (
        move === 'next' &&
        finaliseStages[rankData?.workflow_status - 1]?.transitionNext
      ) {
        return finaliseStages[rankData?.workflow_status - 1]?.transitionNext
      } else if (
        move === 'previous' &&
        finaliseStages[rankData?.workflow_status - 2]?.transitionNext
      ) {
        return (
          'Undo ' +
          finaliseStages[rankData?.workflow_status - 2]?.transitionNext
        )
      }
    }
    return ''
  }

  const [direction, setDirection] = useState<
    stageDirection | 'email' | undefined
  >(undefined)

  return (
    <Box sx={{ maxWidth: 'xl', width: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 1,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="h1">Finalise</Typography>
        </Box>
        <Paper
          sx={{ my: 1, p: 2, width: 1, border: 1, borderColor: 'grey.400' }}
          elevation={0}
        >
          <Stepper
            activeStep={
              rankData?.workflow_status ? rankData?.workflow_status - 1 : 0
            }
            sx={{ width: 1, mt: 1, mb: 2 }}
            alternativeLabel
          >
            {finaliseStages.map((stage, index) => {
              return (
                <Step
                  key={index}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <StepLabel
                    optional={
                      <Typography variant="caption">{stage.caption}</Typography>
                    }
                  >
                    {stage.stage}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <Box sx={{ width: 1, display: 'flex' }}>
            <Box>
              {stepLabel(rankData?.workflow_status, 'previous') && (
                <LoadingButton
                  loading={stageLoading && direction === 'previous'}
                  variant={'outlined'}
                  onClick={() => {
                    setDirection('previous')
                    updateRankTrialStage({
                      rankStage: 'previous_stage',
                      currentWorkflowStatus: rankData?.workflow_status,
                    })
                  }}
                  startIcon={<Undo />}
                  color={'error'}
                  disabled={
                    rankData?.workflow_status === 1 ||
                    rankData?.workflow_status === finaliseStages.length
                  }
                >
                  {stepLabel(rankData?.workflow_status, 'previous')}
                </LoadingButton>
              )}
            </Box>
            <Box sx={{ display: 'flex', ml: 'auto', flexDirection: 'column' }}>
              {stepLabel(rankData?.workflow_status, 'next') && (
                <LoadingButton
                  loading={stageLoading && direction === 'next'}
                  variant={'outlined'}
                  color={'success'}
                  onClick={() => {
                    setDirection('next')
                    updateRankTrialStage({
                      rankStage: 'next_stage',
                      currentWorkflowStatus: rankData?.workflow_status,
                    })
                  }}
                  startIcon={
                    rankData?.workflow_status === finaliseStages.length - 1 && (
                      <CheckCircle />
                    )
                  }
                  endIcon={<ArrowForward />}
                  disabled={
                    rankData?.workflow_status === finaliseStages.length ||
                    (rankData?.workflow_status === 3 &&
                      !currentUser?.can_send_email)
                  }
                >
                  {stepLabel(rankData?.workflow_status, 'next')}
                </LoadingButton>
              )}
              {rankData?.workflow_status === finaliseStages.length - 1 &&
                stepLabel(rankData?.workflow_status, 'next') &&
                currentUser?.can_send_email && (
                  <LoadingButton
                    loading={emailLoading}
                    sx={{ mt: 1 }}
                    variant={'outlined'}
                    color={'success'}
                    onClick={() => {
                      setDirection('email')
                      sendEmail(rankData?.workflow_status)
                    }}
                    startIcon={<Send />}
                    endIcon={<ArrowForward />}
                    disabled={
                      rankData?.workflow_status === finaliseStages.length ||
                      emailInvalid(email)
                    }
                  >
                    {`Send email & ${stepLabel(rankData?.workflow_status, 'next')}`}
                  </LoadingButton>
                )}
            </Box>
          </Box>
        </Paper>
        {rankData?.workflow_status &&
          rankData.workflow_status === 3 &&
          currentUser?.can_send_email && (
            <PrepareEmail
              email={email}
              setEmail={setEmail}
              templateId={templateId}
              setTemplateId={setTemplateId}
            />
          )}
        <Box
          sx={{ display: 'flex', width: 1, justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex' }}>
            <FormControl
              size={'small'}
              fullWidth
              variant="outlined"
              sx={{ minWidth: 200 }}
            >
              <InputLabel>Assigned User</InputLabel>
              <Select
                label={'Assigned User'}
                required
                disabled={patientData?.rank_id == null}
                value={criteriaData?.assigned_user || ''}
                onChange={(e) =>
                  patchCriteria({
                    assigned_user: e.target.value,
                  })
                }
                name="assignedUser"
              >
                {usersList}
              </Select>
            </FormControl>
            <Box sx={{ ml: 1 / 2, alignItems: 'center', display: 'flex' }}>
              <IconButton
                tabIndex={-1}
                onClick={() =>
                  patchCriteria({
                    assigned_user: null,
                  })
                }
              >
                <Clear />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <LoadingButton
                variant={'outlined'}
                startIcon={<Download />}
                fullWidth
                loading={reportLoading}
                onClick={() => {
                  getReport()
                }}
              >
                Download Report
              </LoadingButton>
            </Box>
            {nataReportLink && (
              <Box sx={{ ml: 1 }}>
                <LoadingButton
                  variant={'outlined'}
                  startIcon={<Download />}
                  fullWidth
                  onClick={() => {
                    window.open(nataReportLink, '_blank')
                  }}
                >
                  NATA Report
                </LoadingButton>
              </Box>
            )}
          </Box>
        </Box>
        <Notes />
        <PDFCustomText />
      </Box>
    </Box>
  )
}

export default Finalise
