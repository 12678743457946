import { User } from '@auth0/auth0-react'
import { format, parseISO } from 'date-fns'

export function formatUser(
  usersData: User[] | undefined,
  user_id: number,
): string {
  if (user_id == null) {
    return '-unknown user-'
  }
  let user =
    usersData ? usersData.find((user) => user.id === user_id) : undefined
  return user ?
      `${user?.first_name !== '' || user?.last_name !== '' ? user?.first_name + ' ' + user?.last_name : user?.username}`
    : user_id.toString()
}

export function formatDate(dbDate: string) {
  if (dbDate) {
    return format(parseISO(dbDate), 'yyyy-MM-dd HH:mm:ss')
  } else {
    return ''
  }
}
