import { GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { IconButton, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import React from 'react'

function DataGridToolbar(props: any) {
  const {
    setRows,
    setRowModesModel,
    title,
    initialValues = { id: 'new' },
    editDisabled = false,
  } = props

  const handleClick = () => {
    setRows((oldRows: any) => [...oldRows, initialValues])
    setRowModesModel((oldModel: any) => ({
      ...oldModel,
      [initialValues['id']]: { mode: GridRowModes.Edit },
    }))
  }

  return (
    <GridToolbarContainer>
      <Typography
        sx={{ fontWeight: 'bold', ml: 1 }}
        variant={'subtitle1'}
      >
        {title}
      </Typography>
      <IconButton
        sx={{ ml: 'auto', color: 'inherit' }}
        onClick={handleClick}
        disabled={editDisabled}
      >
        <Add />
      </IconButton>
    </GridToolbarContainer>
  )
}

export default DataGridToolbar
