import client from './API_Config'

async function getCanned(accessToken: Promise<string>) {
  return await client(await accessToken)
    .get<any>(`/myapi/cannedcomment/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function addCanned(accessToken: Promise<string>, body: any) {
  return await client(await accessToken)
    .post<any>(`/myapi/cannedcomment/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function updateCanned(
  accessToken: Promise<string>,
  id: string,
  body: any,
) {
  return await client(await accessToken)
    .patch<any>(`/myapi/cannedcomment/${id}/`, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function deleteCanned(accessToken: Promise<string>, id: string) {
  return await client(await accessToken)
    .delete<any>(`/myapi/cannedcomment/${id}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const CannedService = {
  getCanned,
  addCanned,
  updateCanned,
  deleteCanned,
}

export default CannedService
