import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { HeaderCell } from '../../../../components/Table'
import CellFormat from '../CellFormat'
import React from 'react'
import { PatientData } from '../../../../typescript/API'
import TableLoading from '../TableLoading'

function ReportHistoryTable(
  maxHeight: number,
  patientData: PatientData | undefined,
) {
  return (
    <Paper
      elevation={5}
      sx={{ width: 1 }}
    >
      <TableContainer sx={{ width: 1, maxHeight: maxHeight }}>
        <Table
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <HeaderCell>Report History</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientData?.ranked_list_history &&
              patientData.ranked_list_history.map((key) => (
                <TableRow key={key}>
                  <CellFormat value={key} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!patientData?.ranked_list_history && <TableLoading />}
      </TableContainer>
    </Paper>
  )
}

export default ReportHistoryTable
