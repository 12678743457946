import { Box, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Flag } from '@mui/icons-material'
import { MenuButtonIconProps } from '../../MenuButtonProps'
import { usePatientContext } from '../../PatientLoad'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios/index'
import MyApiService from '../../../APIs/MyApi'
import { enqueueSnackbar } from 'notistack'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { useAuth0 } from '@auth0/auth0-react'

function TTFlagMenu() {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()

  const { patientData, criteriaData, refetchCriteria } = usePatientContext()

  const [moldbFlags, setMoldbFlags] = useState<string>('')
  const [pottrFlags, setPottrFlags] = useState<string>('')

  // UPDATE local state from passed criteria data
  useEffect(() => {
    if (criteriaData?.moldb_flags) {
      setMoldbFlags(criteriaData.moldb_flags)
    }
    if (criteriaData?.pottr_flags) {
      setPottrFlags(criteriaData.pottr_flags)
    }
  }, [criteriaData])

  const { mutate: patchCriteria } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    (criteria) =>
      MyApiService.patchCriteria(
        getAccessTokenSilently(),
        criteriaData!.id,
        criteria,
      ),
    {
      onSuccess: () => {
        enqueueSnackbar('Changes Saved!', {
          autoHideDuration: 1500,
          variant: 'success',
        })
        refetchCriteria()
      },
      onError: (err) => {
        setError(err)
        setPottrFlags(criteriaData?.moldb_flags || '')
        setMoldbFlags(criteriaData?.moldb_flags || '')
      },
    },
  )

  async function updateMoldbFlags(moldb_flags: string) {
    if (
      moldbFlags !== criteriaData?.moldb_flags &&
      !(moldbFlags === '' && criteriaData?.moldb_flags === null)
    ) {
      patchCriteria({
        moldb_flags: moldb_flags.length > 0 ? moldb_flags : null,
      })
    }
  }

  async function updatePottrFlags(pottr_flags: string) {
    if (
      pottr_flags !== criteriaData?.pottr_flags &&
      !(pottrFlags === '' && criteriaData?.pottr_flags === null)
    ) {
      patchCriteria({
        pottr_flags: pottr_flags.length > 0 ? pottr_flags : null,
      })
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <TextField
        value={pottrFlags || ''}
        label="POTTR"
        margin="dense"
        onChange={(e) => setPottrFlags(e.target.value)}
        onBlur={(e) => updatePottrFlags(e.target.value)}
        fullWidth
        disabled={patientData?.criteria_id == null}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ color: 'inherit' }}
            >
              <Flag sx={MenuButtonIconProps} />
            </InputAdornment>
          ),
        }}
        sx={{ mx: 1 / 2 }}
      />
      <TextField
        value={moldbFlags || ''}
        label="MOLDB"
        margin="dense"
        onChange={(e) => setMoldbFlags(e.target.value)}
        onBlur={(e) => updateMoldbFlags(e.target.value)}
        fullWidth
        disabled={patientData?.criteria_id == null}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ color: 'inherit' }}
            >
              <Flag sx={MenuButtonIconProps} />
            </InputAdornment>
          ),
        }}
        sx={{ mx: 1 / 2 }}
      />
    </Box>
  )
}

export default TTFlagMenu
