import React from 'react'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import SortableTable from './SortableTable'
import { Box, Grid, Typography } from '@mui/material'
import { usePatientContext } from '../../PatientLoad'

function Other() {
  const { secondaryTables, setSecondaryTables } = usePatientContext()

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragSecondary(event: any) {
    const { active, over } = event
    if (active.id !== over.id) {
      setSecondaryTables((items: string[]) => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography variant="h1">Other</Typography>
      </Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragSecondary}
      >
        <SortableContext
          items={secondaryTables}
          strategy={rectSortingStrategy}
        >
          <Grid
            container
            alignItems="center"
            spacing={5}
          >
            {secondaryTables.map((id, index) => (
              <SortableTable
                key={id}
                index={index}
                id={id}
              />
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </Box>
  )
}

export default Other
