import React, { useEffect, useState } from 'react'
import { Paper, TableContainer } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CommentInterface } from '../../../typescript/API'
import { AxiosResponse } from 'axios'
import CommentService from '../../../APIs/MyApi_Comment'
import usePatientId from '../../../Hooks/usePatientId'
import { usePatientContext } from '../../PatientLoad'
import { useAuth0 } from '@auth0/auth0-react'
import { customTextCols } from './CustomTextCols'
import DataGridToolbar from '../../Settings/DataGridToolbar'
import { DataGridActionCol } from '../../DataGridActionCol'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { useGlobalContext } from '../../LoadConfig'
import {
  DataGridPro,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-pro'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

function PDFCustomText() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { setError } = useMessagePopupContext()
  const queryClient = useQueryClient()
  const patientId = usePatientId()

  const { criteriaData, molArchive, rankData } = usePatientContext()

  const { currentUser } = useGlobalContext()

  const { data: comments, refetch: refetchComments } = useQuery<
    CommentInterface[],
    AxiosResponse
  >(
    ['CommentService', 'getComments', patientId],
    () =>
      CommentService.getComments(getAccessTokenSilently(), criteriaData?.id!),
    {
      enabled: isAuthenticated && !!criteriaData?.id,
      onError: (err) => setError(err),
    },
  )

  const { mutate: deleteComment } = useMutation<any, AxiosResponse, any>(
    ({ id }) => CommentService.deleteComment(getAccessTokenSilently(), id),
    {
      onSuccess: () =>
        queryClient
          .invalidateQueries({ queryKey: ['CommentService'] })
          .then(() => refetchComments()),
      onError: (err) => setError(err),
    },
  )

  const { mutate: addComment } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(({ body }) => CommentService.addComment(getAccessTokenSilently(), body), {
    onSuccess: () =>
      queryClient
        .invalidateQueries({ queryKey: ['CommentService'] })
        .then(() => refetchComments()),
    onError: (err) => {
      setRows((r) => r.filter((row) => row.id !== 'new'))
      setError(err)
    },
  })

  const { mutate: updateComment } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    ({ id, body }) =>
      CommentService.updateComment(getAccessTokenSilently(), id, body),
    {
      onSuccess: () =>
        queryClient
          .invalidateQueries({ queryKey: ['CommentService'] })
          .then(() => refetchComments()),
      onError: (err) => {
        refetchComments().then(setError(err))
      },
    },
  )

  const [rows, setRows] = useState<any[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    if (comments) {
      setRows(comments)
    }
  }, [comments])

  function editDisabled(): boolean {
    return !rankData?.workflow_status || rankData.workflow_status > 2
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    deleteComment({ id })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    if (id === 'new') {
      setRows((r) => r.filter((row) => row.id !== id))
    }
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const [dbOrder, setDbOrder] = useState<number[]>([])
  const [newOrder, setNewOrder] = useState<number[]>([])

  // update the local state on DB update
  useEffect(() => {
    if (comments) {
      const initialOrder = comments
        .sort((a, b) => (a.display_order ?? 0) - (b.display_order ?? 0))
        .map((a) => a.id)
      setDbOrder(initialOrder)
      setNewOrder(initialOrder)
    }
  }, [comments])

  // update the DB state on local update
  useEffect(() => {
    if (newOrder.length === dbOrder.length) {
      let posChanges: any = {}
      newOrder.forEach((item, index) => {
        if (dbOrder[index] !== item) {
          posChanges[item] = index + 1
        }
      })

      if (Object.keys(posChanges).length === 2) {
        setDbOrder(newOrder)
        updateComment({
          id: Object.keys(posChanges)[0],
          body: { display_order: Object.values(posChanges)[0] },
        })
        updateComment({
          id: Object.keys(posChanges)[1],
          body: { display_order: Object.values(posChanges)[1] },
        })
      }
    }
  }, [newOrder, dbOrder, updateComment])

  const handleUp = (value: number) => {
    const currentIndex = dbOrder.indexOf(value)
    const newOrder = [...dbOrder]
    newOrder.splice(currentIndex - 1, 0, value)
    newOrder.splice(currentIndex + 1, 1)
    setNewOrder(newOrder)
  }

  const handleDown = (value: number) => {
    const currentIndex = dbOrder.indexOf(value)
    const newOrder = [...dbOrder]
    newOrder.splice(currentIndex + 2, 0, value)
    newOrder.splice(currentIndex, 1)
    setNewOrder(newOrder)
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    const body = {
      comment: newRow.comment,
      criteria: criteriaData?.id,
      location: newRow.location,
      style: newRow.style,
      author: newRow.author,
      display_order: newRow.display_order,
    }
    if (newRow.id === 'new') {
      addComment({ body })
    } else {
      updateComment({
        body,
        id: newRow.id,
      })
    }
    return newRow
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    event.defaultMuiPrevented = true
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: 1,
        mt: 2,
        // minHeight: 300
      }}
    >
      <DataGridPro
        sx={{
          '& .MuiDataGrid-cell': {
            alignContent: 'center',
          },
        }}
        hideFooter
        columns={[
          ...customTextCols,
          {
            headerName: 'Order',
            field: 'display_order',
            type: 'actions',
            width: 100,
            sortable: true,
            getActions: (params: GridRowParams) => {
              return [
                <GridActionsCellItem
                  icon={<ArrowUpward />}
                  onClick={() => handleUp(params.id as number)}
                  label="Up"
                  disabled={
                    newOrder.indexOf(params.row.id) === 0 || editDisabled()
                  }
                />,
                <GridActionsCellItem
                  icon={<ArrowDownward />}
                  onClick={() => handleDown(params.id as number)}
                  label="Down"
                  disabled={
                    newOrder.indexOf(params.row.id) === newOrder.length - 1 ||
                    editDisabled()
                  }
                />,
              ]
            },
          },
          DataGridActionCol(
            rowModesModel,
            handleSaveClick,
            handleCancelClick,
            handleEditClick,
            handleDeleteClick,
            editDisabled(),
          ),
        ]}
        rows={rows}
        autoHeight
        getRowHeight={() => 'auto'}
        disableColumnMenu
        editMode={'row'}
        processRowUpdate={processRowUpdate}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(n) => setRowModesModel(n)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        initialState={{
          columns: {
            columnVisibilityModel: {
              criteria: false,
            },
          },
          sorting: {
            sortModel: [{ field: 'display_order', sort: 'asc' }],
          },
        }}
        slots={{
          toolbar: DataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            title: 'PDF Custom Text',
            initialValues: {
              id: 'new',
              style: molArchive?.comments?.default.style,
              location: molArchive?.comments?.default.location,
              author: currentUser?.id,
              display_order: rows.length + 1,
            },
            editDisabled: editDisabled(),
          },
        }}
      />
    </TableContainer>
  )
}

export default PDFCustomText
