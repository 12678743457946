import { Box } from '@mui/material'
import { green, red } from '@mui/material/colors'

const ContentWrapper = ({
  topOffset,
  leftOffset = 0,
  children,
}: {
  topOffset: number
  leftOffset?: number
  children: any
}) => {
  return (
    <Box
      sx={{
        mt: topOffset / 10,
        ml: leftOffset / 10,
        overflow: 'auto',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: `calc(100vh - ${topOffset}px)`, //40px = 5 * 2padding * (top + bottom)
        '& .MuiDataGrid-booleanCell[data-value="true"]': {
          color: green.A700 + ' !important',
        },
        '& .MuiDataGrid-booleanCell[data-value="false"]': {
          color: red.A700 + ' !important',
        },
        '& .MuiFormLabel-asterisk': {
          color: 'red',
          fontSize: '1.2rem',
        },
        bgcolor: 'background.default',
      }}
    >
      {children}
    </Box>
  )
}

export default ContentWrapper
