import React from 'react'

import Header from './Header'
import Router from '../../Router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import ConfigLoad from '../LoadConfig'
import EnvBar from '../../garvan-react/Components/EnvBar'
import SelectPatientLoad from '../LoadSelectPatient'
import { CssBaseline } from '@mui/material'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import packageJson from '../../../package.json'
import MessagePopup from '../../garvan-react/Components/ModalFeedback/MessagePopup'
import { useSysInfoContext } from '../LoadSysInfo'
import { defaultTheme, omicoPalette } from '../../garvan-react/Constants/Theme'
import { appBar, envBar } from '../../garvan-react/Constants/StyleConstants'
import ContentWrapper from '../../garvan-react/Components/ContentWrapper'
import { Copyright } from '../../garvan-react/Components/Copyright'

export const pBar = 60

const Home = () => {
  const themeConfig = createTheme({
    ...defaultTheme,
    palette: omicoPalette,
  })
  const { isAuthenticated } = useAuth0()
  const { sysInfo } = useSysInfoContext()
  const location = useLocation()
  const showEnvBar = process.env.REACT_APP_STAGE !== 'production'
  const showPatientBar =
    isAuthenticated && location.pathname.match(/.*\/frontend\/[A-Z][0-9]+\/.*/g)
  let topOffset = appBar + (showPatientBar ? pBar : 0)
  let topOffsetEnv = topOffset + (showEnvBar ? envBar : 0)

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${process.env.REACT_APP_UI_ABBREV ? `[${process.env.REACT_APP_UI_ABBREV}]` : ''} ${packageJson.shortName}`}</title>
      </Helmet>
      <MessagePopup api_v={sysInfo?.api_version}>
        <ThemeProvider theme={themeConfig}>
          <ConfigLoad>
            <SelectPatientLoad>
              <CssBaseline />
              <Header />
              {showEnvBar && (
                <EnvBar
                  env={`${process.env.REACT_APP_UI_NAME} ${packageJson.version}`}
                  topOffset={topOffset}
                />
              )}
              <ContentWrapper topOffset={topOffsetEnv}>
                <Router />
                <Copyright
                  owner={'Omico'}
                  link={'https://www.omico.com.au/'}
                />
              </ContentWrapper>
            </SelectPatientLoad>
          </ConfigLoad>
        </ThemeProvider>
      </MessagePopup>
    </HelmetProvider>
  )
}

export default Home
