export function flipTable(
  secondaryTables: string[],
  primaryTables: string[],
  setSecondaryTables: any,
  setPrimaryTables: any,
  name: string,
) {
  if (secondaryTables.includes(name)) {
    setSecondaryTables(secondaryTables.filter((item) => item !== name))
    let temp = primaryTables
    temp.push(name)
    setPrimaryTables(temp)
  } else {
    setPrimaryTables(primaryTables.filter((item) => item !== name))
    let temp = secondaryTables
    temp.push(name)
    setSecondaryTables(temp)
  }
}
