import React from 'react'
import { TableCell } from '@mui/material'

export const HeaderCell = ({ children }: { children?: any }) => {
  return (
    <TableCell
      sx={{
        bgcolor: 'secondary.main',
        color: 'white',
        fontWeight: 'bold',
        height: '54px',
      }}
    >
      {children}
    </TableCell>
  )
}
