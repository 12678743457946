import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Help } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { LocalError, transformError } from './TransformError'

function RenderError({
  error,
  errorSound,
}: {
  error: LocalError
  errorSound?: any
}) {
  const location = useLocation()
  const localError = transformError(error, location)

  let subject = 'Error!'
  if (localError?.data?.message) {
    subject = localError.data.message
  } else if (localError.data?.detail) {
    subject = localError.data.detail
  } else if (localError?.data && typeof localError.data === 'string') {
    if (localError.data.length > 80) {
      subject = `${localError.data.slice(0, 80)}...`
    } else {
      subject = localError.data
    }
  }

  console.warn('UI Rendered Error', localError)

  return (
    <Alert
      aria-label={'Rendered Error'}
      // variant={'outlined'}
      severity={'error'}
      sx={{
        minWidth: 250,
        maxWidth: 'md',
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
      action={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 150,
            mt: 'auto',
          }}
        >
          <FormHelperText sx={{ textAlign: 'right' }}>
            Copy for IT Helpdesk support
          </FormHelperText>
          <Tooltip
            title={
              <Typography sx={{ fontSize: 16 }}>
                Clicking button will auto-copy all required information for IT
                support (please paste into support ticket)
              </Typography>
            }
          >
            <Button
              onClick={() =>
                navigator.clipboard.writeText(
                  JSON.stringify(localError, null, 2),
                )
              }
              variant={'contained'}
              startIcon={<Help />}
              color={'info'}
            >
              Help
            </Button>
          </Tooltip>
        </Box>
      }
    >
      <AlertTitle>
        <Typography
          aria-label={'Subject'}
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {subject}
        </Typography>
      </AlertTitle>
      {errorSound && (
        <audio
          aria-label={'Playing Error Tone'}
          src={errorSound}
          autoPlay
        />
      )}
      <Stack
        spacing={1 / 2}
        mt={2}
      >
        <Typography
          aria-label={'Status'}
          variant={'body2'}
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          <strong>Status:</strong> {`[${localError?.status}]`}{' '}
          {localError.statusText}
        </Typography>
        {localError.config && (
          <Typography
            aria-label={'API Endpoint'}
            variant={'body2'}
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
          >
            <strong>API:</strong>{' '}
            {localError.config?.method &&
              `[${localError.config?.method.toUpperCase()}]`}{' '}
            {localError.config?.url}
          </Typography>
        )}
        {localError.name && (
          <Typography
            aria-label={'Application Name'}
            variant={'body2'}
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
          >
            <strong>Application:</strong> {localError.name}
          </Typography>
        )}
        <Typography
          aria-label={'Versions'}
          variant={'body2'}
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          <strong>Versions:</strong> {`[UI] v${localError.ui_v}`}{' '}
          {localError?.api_v && `[API] v${localError.api_v}`}
        </Typography>
        <Typography
          aria-label={'Location'}
          variant={'body2'}
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          <strong>Location:</strong>{' '}
          {`[${process.env.REACT_APP_UI_ABBREV}] ${location.pathname}`}
        </Typography>
        <Typography
          aria-label={'Timestamp'}
          variant={'body2'}
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
        >
          <strong>Timestamp:</strong> {localError?.datetime}
        </Typography>
      </Stack>
    </Alert>
  )
}

export default RenderError
