import { TrialView } from '../../../../typescript/API'
import { Box, Typography } from '@mui/material'
import {
  abbrev_genes,
  abbrev_therapies,
  uniq_disp,
} from '../../Functions_Calculate'
import { drugChip } from '../TrialInfoElements'
import React from 'react'

function OtherCard({ trial }: { trial: TrialView }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 1 }}>
      <Box sx={{ flex: 1, margin: 1 / 3 }}>
        <Typography
          variant="body2"
          gutterBottom
        >
          {uniq_disp(trial.uniq_id)}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 3,
          flexDirection: 'column',
          alignItems: 'center',
          margin: 1 / 3,
        }}
      >
        {drugChip(abbrev_therapies(trial.first_col))}
      </Box>
      <Box sx={{ flex: 1, margin: 1 / 3 }}>
        <Typography
          variant="body2"
          gutterBottom
        >
          {trial.display_tier}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, margin: 1 / 3 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold' }}
          gutterBottom
        >
          {abbrev_genes(trial.genes)}
        </Typography>
      </Box>
    </Box>
  )
}

export default OtherCard
