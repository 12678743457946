import {
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { Box, FormHelperText, TextField } from '@mui/material'
import React from 'react'

function CustomEditComponent(props: GridRenderEditCellParams) {
  const { id, value, field } = props
  const apiRef = useGridApiContext()

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue })
  }

  return (
    <Box sx={{ width: 1 }}>
      <TextField
        fullWidth
        multiline
        rows={12}
        value={value}
        onChange={handleValueChange}
        inputProps={{
          ...props.inputProps,
          spellCheck: true, //GCMP-623
        }}
        error={value?.length !== undefined && value?.length < 1}
      />
      {value?.length < 1 && (
        <FormHelperText error>
          You must provide a comment 'subject'!
        </FormHelperText>
      )}
    </Box>
  )
}

export const cannedCommentsCols = [
  {
    headerName: 'Subject',
    field: 'subject',
    flex: 1,
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent {...params} />
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return { ...params.props, error: !params.props.value }
    },
  },
  {
    headerName: 'Comment',
    field: 'comment',
    flex: 3,
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent {...params} />
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return { ...params.props, error: !params.props.value }
    },
  },
  {
    headerName: 'id',
    field: 'id',
  },
]
