import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { footer } from '../Constants/StyleConstants'

export function Copyright({
  owner = 'Garvan Institute of Medical Research',
  link = 'https://www.garvan.org.au/',
}: {
  owner?: string
  link?: string
}) {
  return (
    <Box
      sx={{
        mt: 'auto',
        pt: 4,
        px: 1 / 2,
        pb: 1 / 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: footer,
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {'Copyright © '}
        {new Date().getFullYear()}{' '}
        <Link
          color="inherit"
          href={link}
        >
          {owner}
        </Link>
      </Typography>
    </Box>
  )
}
