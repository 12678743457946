import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocalStorage } from '../Hooks/useLocalStorage'
import {
  CannedComment,
  MolConfig,
  Organisation,
  RecentPatients,
  User,
} from '../typescript/API'
import { DupeState } from '../typescript/UI'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import DuplicatePatientFeedback from './Patient/DuplicatePatientFeedback'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../APIs/MyApi'
import { useAuth0 } from '@auth0/auth0-react'
import CannedService from '../APIs/MyApi_Canned'
import GorgonService from '../APIs/Gorgon'
import { useMessagePopupContext } from '../garvan-react/Components/ModalFeedback/MessagePopup'
import { useSearchParams } from 'react-router-dom'
import { setParam } from './PatientFilterParams'

interface GlobalContextType {
  recentPatients: RecentPatients | undefined
  fetchRecentPatients: any
  cannedComments: CannedComment[] | undefined
  refetchCanned: any
  organisations: Organisation[] | undefined
  defaultOrg: number
  setDefaultOrg: any
  setDuplicateStatus: any
  loading: boolean
  usersData: User[] | undefined
  molConfig: MolConfig | undefined
  currentUser: User | undefined
}

export const GlobalContext = createContext<GlobalContextType>(
  {} as GlobalContextType,
)
export const useGlobalContext = () => useContext(GlobalContext)

function ConfigLoad({ children }: { children: any }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { setError, setMessage } = useMessagePopupContext()
  const [defaultOrg, setDefaultOrg] = useLocalStorage('defaultMTBorg', '')
  const [duplicateStatus, setDuplicateStatus] = useState<DupeState | null>(null)
  const [recentPatients, setRecentPatients] = useState<RecentPatients>(
    {} as RecentPatients,
  )
  let [searchParams, setSearchParams] = useSearchParams({})

  const { mutate: getRecent } = useMutation<any, AxiosResponse>(
    () =>
      GorgonService.sendAction(getAccessTokenSilently(), [
        {
          action: 'recent',
          data: { version: '2.1', orgs: currentUser?.orgs },
        },
      ]),
    {
      onSuccess: (data) => {
        const validated = GorgonService.checkResponse(data)
        if (validated.valid) {
          if (!validated.ok) {
            setMessage({
              message: 'Unable to get recent patients for unknown reason',
              severity: 'warning',
              subject: 'Unexpected Error',
            })
          } else {
            setRecentPatients(validated.response.data)
          }
        } else {
          setMessage({
            message: 'Unable to get recent patients - response not valid',
            severity: 'warning',
            subject: 'Unexpected Error',
          })
        }
      },
      onError: (err) => {
        setError(err)
      },
    },
  )

  const { data: currentUser } = useQuery<User, AxiosResponse>(
    ['MyApiService', 'getCurrent'],
    () => MyApiService.getCurrent(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
      onSuccess: (data) => {
        if (
          data?.first_name &&
          data?.first_name !== '' &&
          data?.last_name &&
          data?.last_name !== '' &&
          !searchParams.get('AssignedUser')
        ) {
          setParam(
            'AssignedUser',
            data.first_name + ' ' + data.last_name,
            setSearchParams,
          )
        }
      },
    },
  )

  const fetchRecentPatients = useMemo(() => {
    if (isAuthenticated && currentUser) {
      return () => getRecent()
    }
    return () => undefined
  }, [getRecent, isAuthenticated, currentUser])

  useEffect(() => {
    fetchRecentPatients()
  }, [defaultOrg, fetchRecentPatients])

  const { mutate: getMolConfig } = useMutation<any, AxiosResponse>(
    () =>
      GorgonService.sendAction(getAccessTokenSilently(), [
        {
          action: 'config',
          version: '2.0',
        },
      ]),
    {
      onSuccess: (data) => {
        const response = GorgonService.checkResponse(data)
        if (response.valid) {
          if (!response.ok || response.response?.data?.success === false) {
            setMessage({
              message: 'Unable to get config for unknown reason',
              severity: 'warning',
              subject: 'Unexpected Error',
            })
          } else {
            setMolConfig(response.response.data)
          }
        } else {
          setMessage({
            message: 'Unable to get config - response not valid',
            severity: 'warning',
            subject: 'Unexpected Error',
          })
        }
      },
      onError: (err) => setError(err),
    },
  )

  const [molConfig, setMolConfig] = useState<MolConfig>({} as MolConfig)
  useEffect(() => {
    if (isAuthenticated) {
      getMolConfig()
    }
  }, [getMolConfig, isAuthenticated])

  const { data: cannedComments, refetch: refetchCanned } = useQuery<
    CannedComment[],
    AxiosResponse
  >(
    ['CannedService', 'getCanned'],
    () => CannedService.getCanned(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
    },
  )

  const { data: usersData } = useQuery<User[], AxiosResponse>(
    ['MyApiService', 'getUsers'],
    () => MyApiService.getUsers(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
    },
  )

  const { data: organisations } = useQuery<Organisation[], AxiosResponse>(
    ['MyApiService', 'getOrgs'],
    () => MyApiService.getOrgs(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
      select: (data) => {
        return data.map((org: Organisation) => {
          return { ...org, name: org.name.slice(4) }
        })
      },
    },
  )

  useEffect(() => {
    if (organisations && organisations.length > 0 && !defaultOrg) {
      setDefaultOrg(organisations[0].id)
    }
  }, [organisations, defaultOrg, setDefaultOrg])

  const loading: boolean = !Boolean(
    cannedComments && recentPatients && organisations,
  )

  return (
    <GlobalContext.Provider
      value={{
        recentPatients,
        fetchRecentPatients,

        cannedComments,
        refetchCanned,

        organisations,

        defaultOrg,
        setDefaultOrg,

        setDuplicateStatus,

        loading,
        usersData,
        currentUser,
        molConfig,
      }}
    >
      {children}
      {/*Duplicate dialogue needs to be external to route as redirect occurs*/}
      <Dialog open={!!duplicateStatus}>
        <DialogContent sx={{ minWidth: 'sm' }}>
          <DialogContentText>
            {DuplicatePatientFeedback(duplicateStatus)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {duplicateStatus &&
            ['failed', 'successful'].includes(duplicateStatus?.status) && (
              <Button
                onClick={() => setDuplicateStatus(null)}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </GlobalContext.Provider>
  )
}

export default ConfigLoad
