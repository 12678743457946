import client from './API_Config'

async function getPatientRef(accessToken: Promise<string>, patient_id: string) {
  return await client(await accessToken)
    .get<any>(`/ext_api/patient/${patient_id}/mtb`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function duplicatePatient(
  accessToken: Promise<string>,
  patient_id: string,
  newRef: string,
  copyRef: string,
) {
  return await client(await accessToken)
    .post<any>(`/ext_api/patient/${patient_id}/mtb/${newRef}?copyof=${copyRef}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

async function uploadJson(
  accessToken: Promise<string>,
  jsonInput: any,
  mtb_ref: string,
) {
  return await client(await accessToken)
    .post<any>(`/ext_api/mtb/${mtb_ref}`, jsonInput, {
      headers: { 'content-type': 'application/json' },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response))
}

const ExtApiService = {
  getPatientRef,
  duplicatePatient,
  uploadJson,
}

export default ExtApiService
