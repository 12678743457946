import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material'
import HTMLEditor from './HTMLEditor'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { EmailTemplate } from '../../../typescript/API'
import { AxiosResponse } from 'axios'
import EmailService from '../../../APIs/MyApi_Email'
import { useAuth0 } from '@auth0/auth0-react'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'

function PrepareEmail({
  email,
  setEmail,
  templateId,
  setTemplateId,
}: {
  email: Partial<EmailTemplate>
  setEmail: any
  templateId: number | null
  setTemplateId: any
}) {
  const { setError } = useMessagePopupContext()

  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const { data: emailList } = useQuery<EmailTemplate[], AxiosResponse>(
    ['EmailService', 'getTemplates'],
    () => EmailService.getTemplates(getAccessTokenSilently()),
    {
      enabled: isAuthenticated,
      onError: (err) =>  setError(err),
      onSuccess: (data) => {
        if (templateId === null && data.length > 0) {
          const defaults = data.filter((t) => t.default)
          if (defaults.length > 0) {
            setTemplateId(defaults[0].id)
          } else {
            setTemplateId(data[0].id)
          }
        }
      },
    },
  )

  let emailOptions = [
    <MenuItem
      value={undefined}
      key={'loading'}
    >
      {'loading...'}
    </MenuItem>,
  ]
  if (emailList) {
    emailOptions = emailList
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
      .map((template) => (
        <MenuItem
          value={template.id}
          key={template.id}
        >
          {template.name}
        </MenuItem>
      ))
  }

  return (
    <Box sx={{ width: 1 }}>
      <Box sx={{ width: 1, display: 'flex' }}>
        <FormControl
          margin="normal"
          fullWidth
          variant="outlined"
          sx={{ mr: 2, flex: 1 }}
        >
          <InputLabel>Email Template</InputLabel>
          <Select
            label={'Email Template'}
            required
            value={templateId || ''}
            onChange={async (e) => {
              setTemplateId(e.target.value as number)
            }}
          >
            {emailOptions}
          </Select>
        </FormControl>
        <TextField
          sx={{ flex: 2 }}
          label={'Subject:'}
          value={email?.subject || ''}
          margin="normal"
          fullWidth
          onChange={(e) =>
            setEmail((email: any) => {
              return { ...email, subject: e.target.value }
            })
          }
          error={!email?.subject}
          helperText={!email?.subject ? 'This is a required field' : undefined}
        />
      </Box>
      <Box sx={{ width: 1, display: 'flex', mt: 1, mb: 2 }}>
        <Box sx={{ mr: 2, width: 1 }}>
          <Autocomplete
            multiple
            onChange={(event: any, newValue: any) => {
              setEmail((e: any) => {
                return { ...e, to: newValue }
              })
            }}
            value={email?.to || []}
            freeSolo
            options={[]}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth
                label="To:"
                placeholder={'john.smith@gmail.com'}
                error={!email?.to || email.to.length < 1}
                helperText={
                  !email?.to || email.to.length < 1 ?
                    'This is a required field'
                  : undefined
                }
              />
            )}
          />
          <FormHelperText>
            Click <i>Enter</i> to save email to list
          </FormHelperText>
        </Box>
        <Autocomplete
          multiple
          fullWidth
          value={email?.cc || []}
          onChange={(event: any, newValue: any) => {
            setEmail((e: any) => {
              return { ...e, cc: newValue }
            })
          }}
          freeSolo
          options={[]}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              label="Cc:"
              placeholder={'john.smith@gmail.com'}
            />
          )}
        />
      </Box>
      <Paper
        sx={{
          my: 1,
          width: 1,
          border: 1,
          borderColor: 'grey.400',
          '& .rdw-editor-main': {
            px: 2,
            pb: 1,
          },
        }}
        elevation={0}
      >
        <HTMLEditor
          value={email?.body}
          setValue={setEmail}
        />
      </Paper>
    </Box>
  )
}

export default PrepareEmail
