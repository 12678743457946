import React from 'react'
import Home from './containers/Home/Home'
import { SnackbarProvider } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import SysInfoLoad from './containers/LoadSysInfo'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LicenseInfo } from '@mui/x-license'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO!)

function App() {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true })
  }

  let clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  let audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={clientId!}
      authorizationParams={{
        redirect_uri: window.location.origin + '/frontend',
        scope: 'api_access profile name email',
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <QueryClientProvider client={queryClient}>
        {process.env.REACT_APP_STAGE === 'local' && (
          <ReactQueryDevtools position="bottom-right" />
        )}
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3500}
        >
          <SysInfoLoad>
            <Home />
          </SysInfoLoad>
        </SnackbarProvider>
      </QueryClientProvider>
    </Auth0Provider>
  )
}

export default App
