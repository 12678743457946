import React from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@mui/material'
import packageJson from '../../package.json'
import { RenderStatus } from '../containers/RenderStatus'

function RequiredAuth() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error: authError,
  } = useAuth0()

  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')
  const error_description = searchParams.get('error_description')

  if (isLoading)
    return (
      <RenderStatus
        message={'Authenticating...'}
        severity={'warning'}
      />
    )
  if (authError)
    return (
      <RenderStatus
        message={`Error Authenticating${error ? ': ' + error : ''}`}
        loading={false}
        description={error_description ? error_description : ''}
      />
    )
  if (isAuthenticated) {
    return <Outlet />
  } else {
    return (
      <Box sx={{ maxWidth: 'sm', width: 1 }}>
        <Box
          sx={{
            display: 'flex',
            verticalAlign: 'middle',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            sx={{ marginTop: 4 }}
          >
            {packageJson.shortName}
          </Typography>
          <Typography variant="h2">v{packageJson.version}</Typography>
          <Button
            type="submit"
            variant={'contained'}
            onClick={() => loginWithRedirect()}
            sx={{ m: 2 }}
          >
            Login
          </Button>
        </Box>
      </Box>
    )
  }
}

export default RequiredAuth
