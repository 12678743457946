import React from 'react'
import { Box, Typography } from '@mui/material'
import RenderTOE from './RenderTOE'
import { v4 as uuid } from 'uuid'
import { DragDropContext } from 'react-beautiful-dnd'
import { useAuth0 } from '@auth0/auth0-react'
import { usePatientContext } from '../../PatientLoad'
import { TOE_View } from '../../../typescript/API'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import RankTrialService from '../../../APIs/MyApi_RankTrials'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import TTModeMenu from './TTModeMenu'
import TTFlagMenu from './TTFlagMenu'
import TTRedoButton from './TTRedoButton'

const PatientTT = () => {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()

  const { toeData, refetchTOE, patientData, rankData } = usePatientContext()

  // TOP => REPORT
  // OTHER => EXTENDED
  // EXCLUDED => EXCLUDED
  const { mutate: updateRanking } = useMutation<
    any,
    AxiosResponse,
    TOE_View,
    () => void
  >(
    (newTOE) =>
      RankTrialService.updateRanking(
        getAccessTokenSilently(),
        patientData!.rank_id,
        newTOE,
      ),
    {
      onSuccess: () => refetchTOE,
      onError: (err) => setError(err)
    },
  )

  async function handleDragEnd(props: any, key: string) {
    const { source, destination } = props[0]

    // dropped outside the list
    if (!destination) {
      return
    }

    if (toeData) {
      if (source.droppableId === destination.droppableId) {
        if (source.index !== destination.index) {
          let list = toeData?.[key][source.droppableId]!
          const [removed] = list.splice(source.index, 1)
          list.splice(destination.index, 0, removed)

          const newToe = {
            ...toeData,
            [key]: {
              ...toeData?.[key],
              [source.droppableId]: list,
            },
          }
          await updateRanking(newToe)
        }
      } else {
        let sourceList = toeData?.[key][source.droppableId]!
        const [removed] = sourceList.splice(source.index, 1)
        let destList = toeData?.[key][destination.droppableId]!
        destList.splice(destination.index, 0, removed)

        const newToe = {
          ...toeData,
          [key]: {
            ...toeData?.[key],
            [source.droppableId]: sourceList,
            [destination.droppableId]: destList,
          },
        }
        await updateRanking(newToe)
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Box sx={{ m: 1, display: 'flex', width: 1 }}>
        <Box sx={{ flex: 1, mx: 2 }} />
        <Typography
          variant="h1"
          sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        >
          Trials & Therapies
        </Typography>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', mx: 2 }}>
          <TTModeMenu />
          <TTRedoButton />
          <TTFlagMenu />
        </Box>
      </Box>
      {toeData &&
        Object.keys(toeData).map((key) => (
          <Box
            sx={{ width: 1 }}
            key={key}
          >
            {key &&
              (toeData[key].top.length > 0 ||
                toeData[key].other.length > 0 ||
                toeData[key].excluded.length > 0) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: 1,
                  }}
                >
                  <Box sx={{ width: 1, justifyContent: 'center', mt: 2 }}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'text.primary',
                      }}
                    >
                      {key}
                    </Typography>
                  </Box>
                  <DragDropContext
                    onDragEnd={async (...props) =>
                      await handleDragEnd(props, key)
                    }
                  >
                    <Box sx={{ display: 'flex', width: 1, minHeight: 300 }}>
                      {Object.keys(toeData[key]).map((value) => (
                        <RenderTOE
                          key={uuid()}
                          otherKey={key}
                          value={value}
                          locked={
                            !rankData?.workflow_status ||
                            rankData?.workflow_status > 2
                          }
                        />
                      ))}
                    </Box>
                  </DragDropContext>
                </Box>
              )}
          </Box>
        ))}
    </Box>
  )
}

export default PatientTT
