import React, { useEffect, useState } from 'react'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  Switch,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material'
import { lighten } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import SortableTable from './SortableTable'
import { useAuth0 } from '@auth0/auth0-react'
import { usePatientContext } from '../../PatientLoad'
import usePatientId from '../../../Hooks/usePatientId'
import { RenderStatus } from '../../RenderStatus'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../../../APIs/MyApi'
import GorgonService from '../../../APIs/Gorgon'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowsProp,
} from '@mui/x-data-grid-pro'

const trimHexColor = (color: any) => {
  if (typeof color === 'string' && color.startsWith('#')) {
    return color.toString().slice(1, 10)
  } else {
    return color
  }
}

const useStyles = makeStyles<{ molArchive: any }>()((theme, { molArchive }) => {
  //finding all unique row hex colours
  let uniqueRowColors: any[] = []
  if (molArchive?.mutation_data) {
    const colors = molArchive.mutation_data.map((item: any) => {
      const color = item?.data?.color
      let colorCode = color
      if (color) {
        colorCode = trimHexColor(color)
      }
      return colorCode
    })
    uniqueRowColors = [...new Set(colors)]
  }

  //finding all unique cell hex colours
  let uniqueCellColors: string[] = []
  if (molArchive?.mutation_data) {
    molArchive.mutation_data.forEach((row: any) => {
      Object.values(row.attributes).forEach((attrib: any) => {
        if (attrib?.color) {
          const colorCode = trimHexColor(attrib.color)
          if (!uniqueCellColors.includes(colorCode)) {
            uniqueCellColors.push(colorCode)
          }
        }
      })
    })
  }

  //initialising style object
  const styles: any = {
    //Applied overrides ("datagrid--")
    //column headers cells
    '& .datagrid--header': {
      color: theme?.palette?.primary?.contrastText,
      backgroundColor: 'black !important',
    },
    //Root overrides ("MuiDataGrid-")
    //hiding cell focus if field isn't editable
    '& .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable):focus ': {
      outline: 'none !important',
      alignContent: 'center',
    },
    '& .MuiDataGrid-cell ': {
      alignContent: 'center',
    },
    // datagrid menu buttons
    '& .MuiIconButton-root': {
      color: theme?.palette?.primary?.contrastText,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
  }

  //adding row class for each row colour
  uniqueRowColors.forEach((color) => {
    styles['& .datagrid--C' + color] = {
      backgroundColor: lighten('#' + color, 0.2),
      '&:hover': {
        backgroundColor: '#' + color,
      },
    }
  })

  //adding cell class for each cellcolour
  uniqueCellColors.forEach((color) => {
    styles['& .C' + trimHexColor(color)] = {
      backgroundColor: /^[a-fA-F0-9]{6}$/.test(color) ? '#' + color : color,
    }
  })

  return {
    root: styles,
  }
})

const Summary = () => {
  const {
    molArchive,
    allMutations,
    setAllMutations,
    refetchCriteria,
    loadingMolArchive,
    setPrimaryTables,
    primaryTables,
    fetchMolArchive,
    criteriaData,
    patientLoading,
    rankData,
  } = usePatientContext()

  const patientId = usePatientId()

  const { setError } = useMessagePopupContext()

  const { getAccessTokenSilently } = useAuth0()

  const [mutationRows, setMutationRows] = useState<GridRowsProp | undefined>(
    undefined,
  )
  const [mutationColumns, setMutationColumns] = useState<GridColDef[]>([])
  const [editing, setEditing] = useState<boolean>(false)

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragPrimary(event: any) {
    const { active, over } = event
    if (active.id !== over.id) {
      setPrimaryTables((items: string[]) => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const { mutate: patchCriteria } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    (variants) =>
      MyApiService.patchCriteria(
        getAccessTokenSilently(),
        criteriaData!.id,
        variants,
      ),
    {
      onSuccess: () => {
        refetchCriteria()
        fetchMolArchive()
      },
      onError: (err) => setError(err),
      onSettled: () => {
        setEditing(false)
      },
    },
  )

  const { mutate: commitCellEdit } = useMutation<
    any,
    AxiosResponse,
    GridRowModel,
    () => void
  >(
    ({ id, field, value }) => {
      return GorgonService.sendAction(getAccessTokenSilently(), [
        {
          action: 'mtbs_api_mtb_patch',
          data: {
            Patient: patientId,
            'MTB Ref': molArchive?.['MTB Ref'],
            action: 'variant_update',
            variant_id: id,
            attributes: {
              [field]: value,
            },
          },
        },
      ])
    },
    {
      onSuccess: (data) => {
        const response = GorgonService.checkResponse(data)
        const errorData = { status: 200, ...data, statusText: data?.received }
        if (response.valid) {
          if (!response.ok || response.response?.data?.success === false) {
            setError(errorData)
            setEditing(false)
          } else {
            patchCriteria({
              variants: response.response?.data?.criteria_data?.variants,
            })
          }
        } else {
          setError(errorData)
          setEditing(false)
        }
      },
      onError: (err) => setError(err),
    },
  )

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    // should only be one key/val changed
    Object.entries(oldRow).forEach(([key, val]) => {
      if (newRow[key] !== val) {
        setEditing(true) // prevent further editing
        commitCellEdit({ id: newRow.id, field: key, value: newRow[key] })
      }
    })
    return newRow
  }

  const errorHandler = (error: any) => {
    console.log('onRowUpdateError')
    console.log(error)
    setEditing(false)
  }

  //moved here to prevent effect loop on initial load
  useEffect(() => {
    const columnWidths: { [key: string]: number } = {
      Notes: 2,
      c: 2,
      Description: 3,
    }

    function SelectEditInputCell(
      props: GridRenderCellParams,
      options: string[],
    ) {
      const { id, value, api, field } = props

      const handleChange = (event: any) => {
        api.setEditCellValue({ id, field, value: event.target.value }, event)
      }

      const handleRef = (element: any) => {
        if (element) {
          element.querySelector(`input[value="${value}"]`).focus()
        }
      }

      return (
        <Select
          ref={handleRef}
          value={value}
          onChange={handleChange}
          fullWidth
        >
          {options.map((item) => (
            <MenuItem
              value={item}
              key={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      )
    }

    if (!loadingMolArchive && molArchive) {
      let temp = molArchive?.mutation_fields.map((item) => {
        let colWidth = 1
        if (item.proportion) {
          colWidth = item.proportion
        } else if (columnWidths?.[item.field]) {
          colWidth = columnWidths[item.field]
        }

        let colDef: any = {
          ...item,
          headerName: item.title,
          flex: colWidth,
          headerClassName: 'datagrid--header',
          renderCell: (params: any) => {
            const value = params.value
            const fullValue =
              params.row[params.field + '-attributes']?.full_value
            const url = params.row[params.field + '-attributes']?.url

            if (url) {
              return (
                <Tooltip
                  title={<Typography variant={'body1'}>{url}</Typography>}
                >
                  <Link
                    color="inherit"
                    onClick={() => {
                      window.open(url, '_blank')
                    }}
                  >
                    {value}
                  </Link>
                </Tooltip>
              )
            } else {
              const newValue = fullValue ? fullValue : value
              return (
                <Tooltip
                  title={
                    <Typography
                      variant={'body1'}
                      sx={{ whiteSpace: 'pre-line' }}
                    >
                      {newValue}
                    </Typography>
                  }
                >
                  <Typography
                    variant={'body2'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {newValue}
                  </Typography>
                </Tooltip>
              )
            }
          },
        }
        if (item?.options) {
          colDef = {
            ...colDef,
            renderEditCell: (props: GridRenderCellParams) =>
              SelectEditInputCell(props, item.options!),
          }
        }
        // regular editing functionality
        if (!rankData?.workflow_status || rankData.workflow_status > 2) {
          colDef['editable'] = false
        }
        // override by JohGra GCMP-591
        if (item?.allow_always) {
          colDef['editable'] = true
        }
        return colDef
      })
      setMutationColumns(temp)
    }
  }, [molArchive, loadingMolArchive, rankData])

  useEffect(() => {
    if (molArchive?.mutation_data) {
      const temp = molArchive.mutation_data?.map((item) => {
        let newStructure: any = {}
        if (item?.attributes) {
          Object.entries(item.attributes).forEach(([key, object]) => {
            const { value, ...attribs } = object
            newStructure[key] = value
            newStructure[key + '-attributes'] = attribs
          })
        }
        return { ...newStructure, rowColor: item.data.color, id: item.data.id }
      })
      setMutationRows(temp)
    }
  }, [molArchive])

  const dgStyle: any = useStyles({ molArchive })

  if (patientLoading) {
    return (
      <RenderStatus
        message={'Loading...'}
        severity={'info'}
      />
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography variant="h1">Clinical and Molecular Summary</Typography>
      </Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragPrimary}
      >
        <SortableContext
          items={primaryTables}
          strategy={rectSortingStrategy}
        >
          <Grid
            container
            alignItems="center"
            spacing={2}
          >
            {primaryTables.map((id, index) => (
              <SortableTable
                key={id}
                index={index}
                id={id}
              />
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
      <Box sx={{ my: 2, width: 1 }}>
        <Typography component="div">
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item>Show Filtered List</Grid>
            <Grid item>
              <Switch
                checked={allMutations}
                onChange={(e) => {
                  setAllMutations(e.target.checked)
                }}
              />
            </Grid>
            <Grid item>Show All</Grid>
          </Grid>
        </Typography>
        {/*Height must be defined as Datagrid virtualises lists and optimises to a consistent height*/}
        <Paper
          elevation={5}
          sx={{ width: 1 }}
        >
          <TableContainer
            className={dgStyle?.classes?.root}
            sx={{ height: 800 }}
          >
            <DataGridPro
              // getRowHeight={() => 'auto'}
              rows={mutationRows ? mutationRows : []}
              columns={mutationColumns as any}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={errorHandler}
              density="compact"
              // bug prevents editRowsModel updating/committing correctly when getRowId is used.
              // Instead adding a unique calculated id to rows
              // getRowId={(row) => row.Gene + row.c}
              disableRowSelectionOnClick
              loading={loadingMolArchive || editing}
              disableColumnSelector={true}
              // MUI forces string classname to be used for row styling
              // https://mui.com/components/data-grid/style/
              // slice is used to remove first # character, as it is invalid in classname
              getRowClassName={(params) => {
                return `datagrid--C${trimHexColor(params.row?.rowColor)}`
              }}
              getCellClassName={(params: GridCellParams) => {
                let color = trimHexColor(
                  params.row[params.field + '-attributes']?.color,
                )
                if (color) {
                  color = 'C' + color
                }
                return color
              }}
            />
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default Summary
