import {
  Box,
  CircularProgress,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { TrialSite } from '../../../../typescript/API'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../../../../APIs/MyApi'
import { useAuth0 } from '@auth0/auth0-react'

function TrialSitePopover({
  trialID,
  anchorEl,
  setAnchorEl,
}: {
  trialID: string
  anchorEl: any
  setAnchorEl: any
}) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const trialSitesQuery = useQuery<TrialSite[], AxiosResponse>(
    ['MyApiService', 'getTrialSite', trialID],
    () => MyApiService.getTrialSite(getAccessTokenSilently(), trialID),
    {
      enabled: isAuthenticated && !!trialID && !!anchorEl,
    },
  )

  const trialQuery = useQuery<any, AxiosResponse>(
    ['MyApiService', 'getTrial', trialID],
    () => MyApiService.getTrial(getAccessTokenSilently(), trialID),
    {
      enabled: isAuthenticated && !!trialID && !!anchorEl,
    },
  )

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', width: 1 }}>
        {trialSitesQuery.error && (
          <Typography>No trial sites found (An error occurred)</Typography>
        )}
        {trialSitesQuery.data && trialSitesQuery.data.length === 0 && (
          <Typography>No trial sites found</Typography>
        )}
        {trialSitesQuery.isLoading && <CircularProgress sx={{ m: 2 }} />}
        {trialSitesQuery.data && trialSitesQuery.data.length > 0 && (
          <Box>
            <Stack gap={1}>
              <Stack
                gap={1}
                direction={'row'}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  {trialQuery.data?.short_title}
                </Typography>
                <Typography>{trialQuery.data?.status}</Typography>
              </Stack>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: trialQuery.data?.eligibility,
                }}
              ></div>
            </Stack>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={3}
                  >
                    Institution
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Postcode</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trialSitesQuery.data.map((trial) => (
                  <TableRow key={trial?.id}>
                    <TableCell>{trial?.institution?.name}</TableCell>
                    <TableCell>{trial?.institution?.postcode}</TableCell>
                    <TableCell>{trial?.institution?.state}</TableCell>
                    <TableCell>{trial?.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </Popover>
  )
}

export default TrialSitePopover
