import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { format } from 'date-fns'
import { FileCopy } from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'
import { MTBRefInput } from '../../../components/MTBrefInput'
import { usePatientContext } from '../../PatientLoad'
import usePatientId from '../../../Hooks/usePatientId'
import { useGlobalContext } from '../../LoadConfig'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import ExtApi from '../../../APIs/ExtApi'
import ExtApiService from '../../../APIs/ExtApi'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'

const Duplicate = () => {
  const patientId = usePatientId()
  const { setError } = useMessagePopupContext()

  const { refetchPatient } = usePatientContext()

  const { setDuplicateStatus } = useGlobalContext()

  const navigate = useNavigate()

  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const [newRef, setNewRef] = useState<string>(format(new Date(), 'yyyy-MM-dd'))
  const [newRefValid, setNewRefValid] = useState<boolean>(true)
  const [copyRef, setCopyRef] = useState<string>('')

  const { data: ref } = useQuery<any, AxiosResponse>(
    ['ExtApiService', 'getPatientRef', patientId],
    () => ExtApiService.getPatientRef(getAccessTokenSilently(), patientId!),
    {
      enabled: isAuthenticated && !!patientId,
      onError: (err) => setError(err),
      onSuccess: (data) => {
        setCopyRef(data.sort().reverse()[0]) //last chronologically
      },
    },
  )

  const { mutate: duplicatePatient } = useMutation<any, AxiosResponse>(
    () =>
      ExtApi.duplicatePatient(
        getAccessTokenSilently(),
        patientId!,
        newRef,
        copyRef,
      ),
    {
      onSuccess: () => {
        setDuplicateStatus({ status: 'successful' })
        refetchPatient()
        navigate(
          `/frontend/${patientId?.toUpperCase()}/latest_ranking/summary/`,
        )
      },
      onError: (e) => {
        setDuplicateStatus({ status: 'failed', error: e })
      },
    },
  )

  return (
    <Box sx={{ maxWidth: '300px', width: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 1,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="h1">Duplicate</Typography>
        </Box>
        {MTBRefInput(newRef, setNewRef, newRefValid, setNewRefValid)}
        <FormControl
          margin="normal"
          fullWidth
        >
          <InputLabel>MTB Ref</InputLabel>
          <Select
            label={'Copy MTB Ref'}
            required
            value={copyRef}
            onChange={(e) => setCopyRef(e.target.value)}
            name="selectedRef"
          >
            {ref ?
              ref
                .sort()
                .reverse()
                .map((item: string) => (
                  <MenuItem
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                ))
            : []}
          </Select>
        </FormControl>
        <Button
          startIcon={<FileCopy />}
          onClick={async () => {
            setDuplicateStatus({ status: 'working' })
            await duplicatePatient()
          }}
          sx={{ m: 2 }}
          variant="contained"
          disabled={!newRefValid}
        >
          Duplicate
        </Button>
      </Box>
    </Box>
  )
}

export default Duplicate
