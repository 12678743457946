import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { FilterAlt } from '@mui/icons-material'
import { MenuButtonIconProps } from '../../MenuButtonProps'
import { usePatientContext } from '../../PatientLoad'
import usePatientId from '../../../Hooks/usePatientId'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios/index'
import MyApiService from '../../../APIs/MyApi'
import RankTrialService from '../../../APIs/MyApi_RankTrials'
import { enqueueSnackbar } from 'notistack'
import TierRankingMode from '../../../Assets/TierRankingMode.png'

function TTModeMenu() {
  const patientId = usePatientId()
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const {
    patientData,
    rankData,
    criteriaData,
    setTierRankingMode,
    tierRankingMode,
    refetchRank,
    refetchPatient,
    refetchCriteria,
  } = usePatientContext()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  async function updateTierRankingMode(newMode: string) {
    if (isAuthenticated && criteriaData?.id && patientId) {
      setTierRankingMode(newMode)
      let vars: { [key: string]: any } = {}
      if (criteriaData?.variants) {
        vars = criteriaData?.variants
      }
      if (typeof vars === 'string') {
        vars = JSON.parse(vars)
      }
      vars['tier_ranking_mode'] = newMode

      const body = {
        variants: vars,
        patient: patientId,
      }
      putCriteria(body)
    }
  }

  const { mutate: putCriteria } = useMutation<
    any,
    AxiosResponse,
    any,
    () => void
  >(
    (criteria) =>
      MyApiService.putCriteria(
        getAccessTokenSilently(),
        criteriaData!.id,
        criteria,
      ),
    {
      onSuccess: (data) => {
        let variants = data.variants
        if (typeof variants === 'string') {
          variants = JSON.parse(variants)
          // save variants as JSON
          data.variants = variants
        }
        refetchCriteria(data)
        redoRanking(data.id)
      },
      onError: (err) => setError(err),
    },
  )

  const { mutate: redoRanking } = useMutation<
    any,
    AxiosResponse,
    string | number,
    () => void
  >(
    (criteriaId) =>
      RankTrialService.redoRanking(getAccessTokenSilently(), {
        criteria_id: criteriaId,
      }),
    {
      onSuccess: (data) => {
        enqueueSnackbar('Successfully Re-Ranked', { variant: 'success' })
        refetchRank(data)
        refetchPatient()
      },
      onError: (err) => setError(err),
    },
  )

  return (
    <>
      <Tooltip
        title={
          <Typography
            sx={{ fontSize: 13 }}
          >{`Change rank mode: "${tierRankingMode}"`}</Typography>
        }
      >
        <IconButton
          onClick={handleClick}
          color="inherit"
        >
          <FilterAlt sx={MenuButtonIconProps} />
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', width: 1 }}>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{ mb: 2 }}
            disabled={
              !rankData?.workflow_status ||
              rankData.workflow_status > 2 ||
              patientData?.criteria_id == null
            }
          >
            <InputLabel>The current tier rank mode is</InputLabel>
            <Select
              label={'The current tier rank mode is'}
              name="tierrank"
              value={tierRankingMode || ''}
              onChange={(e) => updateTierRankingMode(e.target.value! as string)}
            >
              {[
                'pedantic',
                'conservative',
                'balanced',
                'liberal',
                'unrestricted',
              ].map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>
              {' '}
              CHANGING THIS WILL GENERATE A NEW RANKING!
            </FormHelperText>
          </FormControl>
          <img
            src={TierRankingMode}
            alt="omico-logo"
            style={{ width: '500px' }}
          />
        </Box>
      </Popover>
    </>
  )
}

export default TTModeMenu
