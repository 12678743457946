import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material'
import { Clear, Refresh, Visibility } from '@mui/icons-material'
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro'
import SelectPatientToolbar from './SelectPatientToolbar'
import { useGlobalContext } from './LoadConfig'
import { useSelectPatientContext } from './LoadSelectPatient'
import { deleteParam, setParam } from './PatientFilterParams'
import { useSessionStorage } from '../Hooks/useSessionStorage'

const SelectPatient = () => {
  const { recentPatients, fetchRecentPatients, usersData } = useGlobalContext()
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams({})
  const [patientFilter, setPatientFilter] = useSessionStorage(
    'patientFilter',
    {},
  )
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  )
  const {
    sortModel,
    filterModel,
    setSortModel,
    setFilterModel,
    isLoading,
    patientRows,
  } = useSelectPatientContext()

  useEffect(() => {
    if (Object.keys(patientFilter).length > 0) {
      Object.entries(patientFilter).forEach(([key, value]) => {
        setParam(key, value as string, setSearchParams)
      })
      setPatientFilter({})
    }
  }, [patientFilter, setSearchParams, setPatientFilter])

  const usersList =
    usersData ?
      usersData.map((user) => {
        const name =
          user.first_name !== '' || user.last_name !== '' ?
            user.first_name + ' ' + user.last_name
          : user.username
        return (
          <MenuItem
            value={name}
            key={user.id}
          >
            {name}
          </MenuItem>
        )
      })
    : []

  const suffixList =
    patientRows ?
      [...new Set(patientRows.map((p) => p.Suffix))]
        .filter((p) => p)
        .map((p) => {
          return (
            <MenuItem
              value={p}
              key={p}
            >
              {p}
            </MenuItem>
          )
        })
    : []

  return (
    <Box sx={{ maxWidth: 'xl', width: 1 }}>
      <Box
        sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="h1">Select Patient</Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 1,
          width: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Autocomplete
          fullWidth
          sx={{ flex: 1 }}
          onChange={(event: any, newValue: any) => {
            let value = newValue // typed
            if (newValue?.value !== undefined) {
              //selected
              value = newValue?.value
            }
            if (value) {
              let formattedId = value.toUpperCase()
              if (process.env.REACT_APP_DESTINATION === 'CaSP') {
                if (!formattedId.startsWith('C')) {
                  formattedId = 'C' + formattedId.padStart(5, '0')
                }
              } else if (process.env.REACT_APP_DESTINATION === 'MoST') {
                if (!formattedId.startsWith('K')) {
                  formattedId = 'K' + formattedId
                }
              }
              setPatientFilter(Object.fromEntries(searchParams))
              navigate(`/frontend/${formattedId}/latest_ranking/summary/`)
            }
          }}
          freeSolo
          options={
            recentPatients?.attributes ?
              recentPatients.attributes.map((item) => {
                return { value: item.Patient, label: item.Patient }
              })
            : []
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              label="Search by Patient ID"
              placeholder={'Patient ID'}
            />
          )}
        />
        <Box sx={{ display: 'flex', mx: 1, minWidth: 300, flex: 1 }}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel>Assigned User</InputLabel>
            <Select
              label={'Assigned User'}
              value={searchParams.get('AssignedUser') || ''}
              required
              onChange={(e) => {
                setParam('AssignedUser', e.target.value, setSearchParams)
              }}
              name="AssignedUser"
            >
              {usersList}
            </Select>
          </FormControl>
          <Box sx={{ alignItems: 'center', display: 'flex', ml: 1 / 2 }}>
            <IconButton
              tabIndex={-1}
              onClick={() => {
                deleteParam('AssignedUser', setSearchParams)
              }}
            >
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mx: 1, minWidth: 300, flex: 1 }}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel>Suffix</InputLabel>
            <Select
              label={'Suffix'}
              value={searchParams.get('Suffix') || ''}
              required
              onChange={(e) => {
                setParam('Suffix', e.target.value, setSearchParams)
              }}
              name="Suffix"
            >
              {suffixList}
            </Select>
          </FormControl>
          <Box sx={{ alignItems: 'center', display: 'flex', ml: 1 / 2 }}>
            <IconButton
              tabIndex={-1}
              onClick={() => {
                deleteParam('Suffix', setSearchParams)
              }}
            >
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mx: 1, minWidth: 300, flex: 1 }}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel>Status</InputLabel>
            <Select
              label={'Status'}
              value={searchParams.get('WorkflowStatus') || ''}
              required
              onChange={(e) => {
                setParam('WorkflowStatus', e.target.value, setSearchParams)
              }}
              name="Workflow Status"
            >
              <MenuItem
                id="1"
                value="1"
              >
                1
              </MenuItem>
              <MenuItem
                id="2"
                value="2"
              >
                2
              </MenuItem>
              <MenuItem
                id="3"
                value="3"
              >
                3
              </MenuItem>
              <MenuItem
                id="4"
                value="4"
              >
                4
              </MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ alignItems: 'center', display: 'flex', ml: 1 / 2 }}>
            <IconButton
              tabIndex={-1}
              onClick={() => {
                deleteParam('WorkflowStatus', setSearchParams)
              }}
            >
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <Button
          sx={{ width: 200 }}
          variant={'outlined'}
          startIcon={<Refresh />}
          onClick={() => fetchRecentPatients()}
        >
          Refresh List
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ width: 1, height: 'calc(100vh - 350px)' }}
      >
        <DataGridPro
          rows={patientRows
            .filter((r) =>
              searchParams.get('AssignedUser') ?
                r.assigned_user_name.includes(searchParams.get('AssignedUser'))
              : true,
            )
            .filter((r) =>
              searchParams.get('Suffix') ?
                r.Suffix === searchParams.get('Suffix')
              : true,
            )
            .filter((r) =>
              searchParams.get('WorkflowStatus') ?
                r.workflow_status ===
                parseInt(searchParams.get('WorkflowStatus')!)
              : true,
            )}
          columns={
            recentPatients?.columns ?
              [
                {
                  type: 'actions',
                  field: 'config',
                  headerName: '',
                  width: 50,
                  getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                      icon={<Visibility sx={{ fontSize: '1.6rem' }} />}
                      onClick={() => {
                        setPatientFilter(Object.fromEntries(searchParams))
                        navigate(
                          `/frontend/${params.row.Patient}/latest_ranking/summary/`,
                        )
                      }}
                      label="Configure"
                    />,
                  ],
                },
                ...recentPatients.columns.map((col) => {
                  return {
                    field: col,
                    headerName: col,
                    flex: 1,
                  }
                }),
                {
                  field: 'workflow_status',
                  headerName: 'Status',
                  description: 'Workflow Status',
                  flex: 1,
                },
                {
                  field: 'assigned_user_name',
                  headerName: 'User',
                  description: 'Assigned User',
                  flex: 1,
                },
                {
                  ...GRID_CHECKBOX_SELECTION_COL_DEF,
                  width: 50,
                },
              ]
            : []
          }
          loading={isLoading}
          disableColumnMenu
          slots={{
            toolbar: () => SelectPatientToolbar(selectionModel),
          }}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
          }}
          rowSelectionModel={selectionModel}
          checkboxSelection
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                Suffix: false,
              },
            },
          }}
        />
      </TableContainer>
    </Box>
  )
}
export default SelectPatient
