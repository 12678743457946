import { TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'

export function ReadOnlyTextField(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        readOnly: true,
      }}
    />
  )
}
