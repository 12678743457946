import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import React, { useState } from 'react'
import { Upload } from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import MyApiService from '../../APIs/MyApi'
import { useMessagePopupContext } from '../../garvan-react/Components/ModalFeedback/MessagePopup'

function FileUpload(mtbRef: string) {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()

  const [file, setFile] = useState<File | undefined>(undefined)
  const [response, setResponse] = useState<string[] | undefined>(undefined)

  const { mutate: uploadFile, isLoading: uploadLoading } = useMutation<
    any,
    AxiosResponse
  >(() => MyApiService.uploadFile(getAccessTokenSilently(), mtbRef, file), {
    onSuccess: (data) => setResponse(data),
    onError: (err) => setError(err)
  })

  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files !== null) {
            const files: FileList = e.target.files
            setFile(files[0])
          }
        }}
      />
      <Button
        startIcon={<Upload />}
        onClick={async () => await uploadFile()}
        sx={{ m: 2 }}
        variant="contained"
        disabled={!Boolean(file)}
      >
        Upload
      </Button>
      {uploadLoading && (
        <Box sx={{ width: 1, mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
      {typeof response === 'object' && (
        <TableContainer
          component={Paper}
          sx={{ width: 1, mt: 2 }}
        >
          <Table sx={{ width: 1 }}>
            <TableBody>
              {response.map((row: string) => (
                <TableRow key={row}>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {row}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default FileUpload
