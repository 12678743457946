import React from 'react'
import { RenderStatus } from './RenderStatus'

function NotFound() {
  return (
    <RenderStatus
      message={'You are not authorised to view this patient'}
      loading={false}
    />
  )
}

export default NotFound
